import React from "react";
import "./GetFormUsers.module.css";
import "../../sharedStyle/Shared.css";
import Context from "../../context/Context";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./GetFormUsers.module.css";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { reSendEmail } from "../hardCard/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEnvelope,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  root: {
    "& .custome--cell": {
      fontSize: "x-small",
      fontWeight: 900,
    },
    "& .super-app-theme--header": {
      fontWeight: 600,
      fontSize: "meduim",
    },
  },
});

const toolBar = () => {
  return <GridToolbar color="#105e5a" />;
};

function GetFormUsers(props) {
  const { userData, envUrl } = React.useContext(Context);
  const [patrons, setPatrons] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [to, setTo] = React.useState(
    moment().add(3, "days").format("YYYY-MM-DD")
  );
  const [from, setFrom] = React.useState("2020-01-01");
  const [url, setUrl] = React.useState("");
  const [location] = React.useState(useLocation());
  //const [isDataLoaded, setIsDataLoaded] =React.useState(false);
  const history = useHistory();
  const uiClass = useStyles();

  const [columns] = React.useState({
    hardcards: [
      {
        field: "id",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "id",
        flex: 1,
        hide: true,
      },
      {
        field: "first_name",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "Frist Name",
        flex: 1,
      },
      {
        field: "last_name",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "Last Name",
        flex: 1,
      },
      {
        field: "email",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "flag_type",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "Permission",
        flex: 1,
      },
      {
        field: "parking",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "Permission",
        flex: 1,
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Issued at",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "fuid",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        //valueGetter: getFullName,
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonView}
              onClick={() => history.push(`youru/user/${param.value}`)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
            <button
              className={classes.ButtonView}
              onClick={() =>
                reSendEmail(envUrl, param.value, userData.token, {
                  ...param.row,
                  issued: param.row.time_stamp,
                  sent: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                  type: param.row.flag_type,
                  parking: param.row.parking,
                })
              }
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEnvelope} />
            </button>
          </div>
        ),
        flex: 0.7,
      },
    ],
    locations: [
      {
        field: "name",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "Name",
        flex: 0.7,
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Time",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        //valueGetter: getFullName,
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonView}
              onClick={() =>
                history.push(`/yourl/location/${param.value}/${param.row.name}`)
              }
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
          </div>
        ),
        flex: 0.7,
      },
    ],
    forms: [
      {
        field: "name",
        cellClassName: "custom--cell",
        headerClassName: "super-app-theme--header",
        headerName: "Name",
        flex: 0.7,
      },
      {
        field: "auto",
        cellClassName: "custom--cell",
        headerName: "Auto fill",
        flex: 1,
        valueFormatter: (param) => (param.value == "1" ? "yes" : "no"),
      },
      {
        field: "photo",
        cellClassName: "custom--cell",
        headerName: "Requires photo",
        flex: 1,
        valueFormatter: (param) => (param.value == "1" ? "yes" : "no"),
      },
      {
        field: "loc",
        cellClassName: "custom--cell",
        headerName: "Send QR",
        flex: 1,
        valueFormatter: (param) => (param.value == "1" ? "yes" : "no"),
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Time",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        //valueGetter: getFullName,
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonView}
              onClick={() =>
                history.push(`/yourf/form/${param.value}/${param.row.name}`)
              }
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
          </div>
        ),
        flex: 0.7,
      },
    ],
    formCategories: [
      {
        field: "type_name",
        cellClassName: "custom--cell",
        headerName: "Category",
        flex: 0.7,
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Time",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonView}
              onClick={() => history.push("/yourc/" + param.value)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
          </div>
        ),
        flex: 0.7,
      },
    ],
    formData: [
      {
        field: "first_name",
        cellClassName: "custom--cell",
        headerName: "First Name",
        flex: 0.7,
      },
      {
        field: "last_name",
        cellClassName: "custom--cell",
        headerName: "Last Name",
        flex: 0.7,
      },
      {
        field: "email",
        cellClassName: "custom--cell",
        headerName: "Email",
        flex: 1.5,
      },
      {
        field: "phone",
        cellClassName: "custom--cell",
        headerName: "Phone",
        flex: 1,
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Time",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      // {
      //   field: "id",
      //   cellClassName: "custom--cell",
      //   headerName: " ",
      //   cellClassName: "buttonCell",
      //   align: "right",
      //   renderCell: (param) => (
      //     <div className={classes.buttonContainer}>
      //       <button
      //         className={classes.ButtonDelete}
      //         onClick={() => Delete(props.type, param.value)}
      //       >
      //         Delete
      //       </button>
      //     </div>
      //   ),
      //   flex: 0.7,
      // },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonDelete}
              onClick={() => Delete(props.type, param.value)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTrashAlt} />
            </button>

            <button
              className={classes.ButtonView}
              onClick={() => history.push("/youru/user/" + param.row.userID)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
            {props.email ? (
              <button
                className={classes.ButtonView}
                onClick={() => email(param.row.userID)}
              >
                <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEnvelope} />
              </button>
            ) : (
              false
            )}
          </div>
        ),
        flex: 1.5,
      },
    ],
    formUser: [
      {
        field: "first_name",
        cellClassName: "custom--cell",
        headerName: "First Name",
        flex: 0.7,
      },
      {
        field: "last_name",
        cellClassName: "custom--cell",
        headerName: "Last Name",
        flex: 0.7,
      },
      {
        field: "email",
        cellClassName: "custom--cell",
        headerName: "Email",
        flex: 1.5,
      },
      {
        field: "phone",
        cellClassName: "custom--cell",
        headerName: "Phone",
        flex: 1,
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Time",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonDelete}
              onClick={() => Delete(props.type, param.value)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTrashAlt} />
            </button>
            <button
              className={classes.ButtonView}
              onClick={() => history.push("/youru/user/" + param.row.userID)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
            {props.email ? (
              <button
                className={classes.ButtonView}
                onClick={() => email(param.row.userID)}
              >
                <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEnvelope} />
              </button>
            ) : (
              false
            )}
          </div>
        ),
        flex: 1.5,
      },

      // {
      //   field: "userID",
      //   cellClassName: "custom--cell",
      //   headerName: " ",
      //   cellClassName: "buttonCell",
      //   align: "right",
      //   renderCell: (param) => (
      //     <div className={classes.buttonContainer}>
      //       <button
      //         className={classes.ButtonView}
      //         onClick={() => history.push("/youru/user/" + param.value)}
      //       >
      //         <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
      //       </button>
      //     </div>
      //   ),
      //   flex: 0.7,
      // },
    ],
    vaxReport: [
      {
        field: "first_name",
        cellClassName: "custom--cell",
        headerName: "First Name",
        flex: 0.7,
      },
      {
        field: "last_name",
        cellClassName: "custom--cell",
        headerName: "Last Name",
        flex: 0.7,
      },
      {
        field: "email",
        cellClassName: "custom--cell",
        headerName: "Email",
        flex: 1.5,
      },
      {
        field: "vax",
        cellClassName: "custom--cell",
        headerName: "Vaccine",
        valueFormatter: (param) => param.value || "unknown",
        flex: 1.5,
      },
      {
        field: "booster",
        cellClassName: "custom--cell",
        headerName: "Booster",
        flex: 1.5,
      },
      {
        field: "vaxDate",
        cellClassName: "custom--cell",
        headerName: "Last dose",
        flex: 1,
        valueFormatter: (param) =>
        param.value ? moment(param.value).format("DD-MM-YYYY") : 'unknown' ,
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        renderCell: (param) => (
          <button
            className={classes.ButtonView}
            onClick={() => history.push("/youru/user/" + param.value)}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
          </button>
        ),
        flex: 1.5,
      },
    ],
    vaxTierReport: [
      {
        field: "first_name",
        cellClassName: "custom--cell",
        headerName: "First Name",
        flex: 0.7,
      },
      {
        field: "last_name",
        cellClassName: "custom--cell",
        headerName: "Last Name",
        flex: 0.7,
      },
      {
        field: "email",
        cellClassName: "custom--cell",
        headerName: "Email",
        flex: 1.5,
      },
      {
        field: "flag_type",
        cellClassName: "custom--cell",
        headerName: "Tier",
        flex: 1.5,
      },
      {
        field: "vax",
        cellClassName: "custom--cell",
        headerName: "Vaccine",
        valueFormatter: (param) => param.value || "unknown",
        flex: 1.5,
      },
      {
        field: "booster",
        cellClassName: "custom--cell",
        headerName: "Booster",
        flex: 1.5,
      },
      {
        field: "vaxDate",
        cellClassName: "custom--cell",
        headerName: "Last dose",
        flex: 1,
        valueFormatter: (param) =>
        param.value ? moment(param.value).format("DD-MM-YYYY") : 'unknown' ,
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        renderCell: (param) => (
          <button
            className={classes.ButtonView}
            onClick={() => history.push("/youru/user/" + param.value)}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
          </button>
        ),
        flex: 1.5,
      },
    ],
    checkIns: [
      {
        field: "first_name",
        cellClassName: "custom--cell",
        headerName: "First Name",
        flex: 0.7,
      },
      {
        field: "last_name",
        cellClassName: "custom--cell",
        headerName: "Last Name",
        flex: 0.7,
      },
      {
        field: "email",
        cellClassName: "custom--cell",
        headerName: "Email",
        flex: 1.5,
      },
      {
        field: "phone",
        cellClassName: "custom--cell",
        headerName: "Phone",
        flex: 1,
      },
      {
        field: "check_time",
        cellClassName: "custom--cell",
        headerName: "Time Stamp",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonDelete}
              onClick={() => Delete(props.type, param.value)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTrashAlt} />
            </button>
            <button
              className={classes.ButtonView}
              onClick={() => history.push("/youru/user/" + param.row.fuid)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
          </div>
        ),
        flex: 1.5,
      },
      // {
      //   field: "fuid",
      //   cellClassName: "custom--cell",
      //   headerName: " ",
      //   cellClassName: "buttonCell",
      //   align: "right",
      //   renderCell: (param) => (
      //     <div className={classes.buttonContainer}>
      //       <button
      //         className={classes.ButtonView}
      //         onClick={() => history.push("/youru/user/" + param.value)}
      //       >
      //         View
      //       </button>
      //     </div>
      //   ),
      //   flex: 0.7,
      // },
    ],
    userForms: [
      {
        field: "name",
        cellClassName: "custom--cell",
        headerName: "Form",
        flex: 0.7,
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Time",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonDelete}
              onClick={() => Delete(props.type, param.value)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTrashAlt} />
            </button>
            <button
              className={classes.ButtonView}
              onClick={() =>
                history.push(location.pathname + "/viewform/" + param.row.fid)
              }
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEye} />
            </button>
          </div>
        ),
        flex: 1.5,
      },
      // {
      //   field: "fid",
      //   cellClassName: "custom--cell",
      //   headerName: " ",
      //   cellClassName: "buttonCell",
      //   align: "right",
      //   renderCell: (param) => (
      //     <div className={classes.buttonContainer}>
      //       <button
      //         className={classes.ButtonView}
      //         onClick={() =>
      //           history.push(location.pathname + "/viewform/" + param.value)
      //         }
      //       >
      //         View
      //       </button>
      //     </div>
      //   ),
      //   flex: 0.7,
      // },
    ],
    userCheckins: [
      {
        field: "name",
        cellClassName: "custom--cell",
        headerName: "Site",
        flex: 1,
      },
      {
        field: "time_stamp",
        cellClassName: "custom--cell",
        headerName: "Time",
        flex: 1,
        valueFormatter: (param) =>
          moment(param.value).format("DD-MM-YYYY HH:mm:ss"),
        type: "date",
      },
      {
        field: "id",
        cellClassName: "custom--cell",
        headerName: " ",
        cellClassName: "buttonCell",
        align: "right",
        renderCell: (param) => (
          <div className={classes.buttonContainer}>
            <button
              className={classes.ButtonDelete}
              onClick={() => Delete(props.type, param.value)}
            >
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTrashAlt} />
            </button>
          </div>
        ),
        flex: 1,
      },
    ],
  });

  const getExport = (type) => {
    const urls = {
      formData: "/php/exportData.php",
      formUser: "/php/formUser/get/getFormUsers.php",
      checkIns: "/php/exportDataCheckin.php",
    };
    fetch(envUrl + urls[type], {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.id,
        token: userData.token,
        type: props.type,
        verified: props.data.loc,
        to: to,
        from: from,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:csv;base64," + data.data; //Image Base64 Goes here
        a.download = "export.csv"; //File name Here
        a.click();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onLoad = (type, offset = 0, search = "", from, to) => {
    console.log(type);
    const urls = {
      formData: "/php/form/get/getFormDatas.php",
      formUser: "/php/formUser/get/getFormUsers.php",
      vaxReport: "/php/formUser/get/getFormUsersVax.php",
      checkIns: "/php/checkins/get/getCheckins.php",
      userForms: "/php/form/get/getFormDatasUser.php",
      userCheckins: "/php/checkins/get/getCheckinsUser.php",
      formCategories: "/php/form/get/getCategories.php",
      forms: "/php/form/get/getForms.php",
      locations: "/php/location/get/getLocations.php",
      hardcards: "/php/hardCard/get/getHardCardList.php",
      vaxTierReport: "/php/hardCard/get/getHardCardList.php",
    };
    setPatrons([]);
    fetch(envUrl + urls[type], {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search: search,
        to: moment(to).add(1, "days").format("YYYY-MM-DD"),
        from: from,
        offset: offset,
        id: props.id,
        token: userData.token,
        verified: props.data.loc,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "success") {
          console.log(data.data);
          setRowCount(data.rowCount);
          setPatrons([...data.data]);
        } else {
          alert(data.result);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const Delete = (type, id) => {
    const urls = {
      formData: "/php/form/delete/deleteFormData.php",
      formUser: "/php/formUser/delete/deleteFormUser.php",
      checkIns: "/php/checkins/delete/deleteCheckin.php",
      userForms: "/php/form/delete/deleteFormData.php",
      userCheckins: "/php/checkins/delete/deleteCheckin.php",
    };
    console.log(props.id);
    if (!window.confirm("Are you really sure you want to delete?"))
      return false;
    fetch(envUrl + urls[type], {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        formID: props.id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          onLoad(props.type, offset, search, from, to);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const email = (id) => {
    fetch(envUrl + "/php/qrCode/sendQR.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          alert("qr email sent!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    onLoad(props.type, offset, search, from, to);
  }, []);

  return (
    <div className={"VenueFeildsContainerInner " + classes.GetFormUsers}>
      {props.title || props.search ? (
        <div className="css-fda45w">
          <h3 className="css-154x21u">{props.title}</h3>

          {props.search ? (
            <>
              <TextField
                label="From"
                type="date"
                value={from}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(x) => {
                  setFrom(x.target.value);
                  onLoad(props.type, offset, search, x.target.value, to);
                }}
              />

              <TextField
                label="To"
                type="date"
                value={to}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(x) => {
                  setTo(x.target.value);
                  onLoad(props.type, offset, search, from, x.target.value);
                }}
              />

              <div className="css-1a50mvr">
                <input
                  id="query"
                  placeholder="Search patrons..."
                  className="Input Search"
                  value={search}
                  onChange={(x) => setSearch(x.target.value)}
                  onKeyDown={(x) => {
                    if (x.key === "Enter") {
                      setOffset(0);
                      onLoad(props.type, offset, search, from, to);
                    }
                  }}
                />
                <div className="css-rroqgm">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    size="16"
                    height="16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
            </>
          ) : (
            false
          )}
        </div>
      ) : (
        false
      )}
      <div className={classes.table}>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }} className={uiClass.root}>
            {patrons.length ? (
              <DataGrid
                color="#000"
                components={{
                  Toolbar: toolBar,
                }}
                loading={false}
                rows={patrons}
                columns={columns[props.type]}
                columnBuffer={2}
                sortModel={[
                  {
                    field: "id",
                    sort: "desc",
                  },
                ]}
              />
            ) : (
              false
            )}
          </div>
        </div>
      </div>
      {props.title ? (
        <div className="css-y0uto3 patronsFooter">
          <div className="patronButtons">
            {props.add ? (
              <button
                className="Button LightButton"
                onClick={() => history.push(location.pathname + "/add")}
              >
                Add patron
              </button>
            ) : (
              false
            )}
            {false ? (
              <button
                className="Button DarkButton"
                onClick={() => getExport(props.type)}
              >
                Export Data
              </button>
            ) : (
              false
            )}
          </div>
        </div>
      ) : (
        false
      )}
    </div>
  );
}

export default GetFormUsers;
