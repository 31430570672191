import React, { Suspense } from "react";
import "../../sharedStyle/Shared.css";
import Menu from "../../sharedComponents/menu/Menu";

import { useParams } from "react-router-dom";
import Context from "../../context/Context";
import Loading from "../../sharedComponents/loading/Loading";
import { Switch, Route } from "react-router-dom";
const EditLocation = React.lazy(() =>
  import("./components/editLocation/EditLocation")
);
const Overview = React.lazy(() =>
  import("./components/locationOverview/Overview")
);
const Permissions = React.lazy(() =>
  import("./components/permissions/LocationPermissions")
);
const Analytics = React.lazy(() => import("./components/analytics/Analytics"));
const Enquire = React.lazy(() =>
  import("../../sharedComponents/enquire/Enquire")
);
const AddCheckin = React.lazy(() =>
  import("./components/addCheckin/AddCheckin")
);

function YourLocation(props) {
  const { userData, setShowMenuButton, enquire, envUrl } = React.useContext(
    Context
  );
  const [show, setShow] = React.useState("Overview");
  const [data, setData] = React.useState();
  const { type, id } = useParams();

  const getVenueEvent = () => {
    console.log(id);
    fetch(envUrl + "/php/getLocation.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "success") alert(data.result);
        else {
          console.log(data.data);
          setData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    setShowMenuButton(true);
    getVenueEvent();
  }, []);

  return (
    <div className="Page">
      {enquire ? <Enquire /> : false}
      <div className="YourVenue">
        <Menu setShow={setShow} type={type} />
        {!data ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/yourl/:type/:id/:name"
                render={(props) => (
                  <Overview
                    setShow={setShow}
                    data={data}
                    type={type}
                    id={data.id}
                  />
                )}
              />
              <Route
                exact
                path="/yourl/:type/:id/:name/edit"
                render={(props) => (
                  <EditLocation
                    data={data}
                    type={type}
                    id={id}
                    setData={setData}
                  />
                )}
              />
              <Route
                exact
                path="/yourl/:type/:id/:name/analytics"
                render={(props) => (
                  <Analytics data={data} type={type} id={id} />
                )}
              />
              <Route
                exact
                path="/yourl/:type/:id/:name/add"
                render={(props) => (
                  <AddCheckin
                    setShow={setShow}
                    data={data}
                    type={type}
                    id={id}
                  />
                )}
              />
              <Route
                exact
                path="/yourl/:type/:id/:name/permissions"
                render={(props) => (
                  <Permissions data={data} type={type} id={id} />
                )}
              />
            </Switch>
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default YourLocation;
