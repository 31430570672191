import React from "react";
import "./Footer.css";
import "../../sharedStyle/Shared.css";
import {Link} from 'react-router-dom'

function Footer(props) {
  return (
    <footer className="Footer">
      <div className="FooterInner">
        <div className="FooterContainer">
          <div className="FooterContainerInner">
            <Link
               to='/'
              className="Link"
            >
              Home
            </Link>
            <Link
              to='/faq'
              className="Link"
            >
              FAQ
            </Link>
            <Link
              to='/contact'
              className="Link"
            >
              Contact
            </Link>
            <Link
            to='/privacy'
              className="Link"
            >
              Privacy
            </Link>
            <Link
            to='/terms'
              className="Link"
            >
              Terms
            </Link>
          </div>
          <div className="css-vurnku">
            <div className="css-14g5739">© TRACQR 2020</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
