import React from "react";
import { QRCode } from "react-qrcode-logo";
import Context from "../../context/Context";
import { decode, getQueryVariable } from "../../helpers/helpers";
import {validateEmail} from "./helpers"
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image as Img,
} from "@react-pdf/renderer";

//css imports
import classes from "./CustomQR.module.css";
import "../../sharedStyle/Shared.css";

//img imports
import logo from "../../assets/qr.png";
import headerPDF from "../../assets/logo.png";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    width: "100%",
    padding: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  section: {
    padding: 10,
    flexDirection: "row",
  },
  header: {
    height: 80,
    width: 200,
  },
  bold: {
    fontWeight: 700,
    paddingRight: 20,
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: 0,
    padding: 0,
  },
  footer: {
    color: "#d3d3d3",
    marginTop: 30,
    padding: 0,
  },
});

const MyDocument = (qrUrl, logoUrl, data) => (
  <Document style={styles.page}>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Img style={{ width: null, height: "100%" }} style src={headerPDF} />
      </View>
      <View style={{ marginTop: 30, width: 300, height: 300 }}>
        <Img
          style={{ position: "absolute", width: 300, height: 300, zIndex: -1 }}
          src={qrUrl}
        />
        <Img
          style={{
            position: "absolute",
            top: 120,
            left: 120,
            width: 60,
            height: 60,
            zIndex: 99,
          }}
          src={logoUrl}
        />
      </View>
      <Text style={styles.footer}> © TRACQR 2020</Text>
    </Page>
  </Document>
);

var saveData = (function (img1, img2, data) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return async function (img1, img2, data) {
    const blob = await pdf(MyDocument(img1, img2, data)).toBlob();
    let url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  };
})();

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

let loadImage = function (userID, handler, envUrl) {
  var image = new Image();
  var url_image =
    envUrl + "/logos/" + userID + ".png";
  image.onload = function () {
    handler(url_image);
  };
  image.onerror = function () {
    handler(logo);
  };
  image.src = url_image;
};

function QrCode(props) {
  const { envUrl } = React.useContext(Context);
  const [customLogo, setCustomLogo] = React.useState(false);
  const [url, setUrl] = React.useState(false);  
  const [decoded] = React.useState(JSON.parse(decode(getQueryVariable("data"))));
  const [formUrl] = React.useState(getQueryVariable("data"));

  React.useEffect(() => {
    console.log(envUrl, decoded)
    validateEmail(envUrl, decoded.fuid)
    loadImage(decoded.uid, setCustomLogo, envUrl);
    var canvas = document.getElementById("react-qrcode-logo");
    var ctx = canvas.getContext("2d");
    var img = canvas.toDataURL("image/jpg");
    setUrl(img);
  }, []);

  return (
    <div className="EditVenue">
      <div className={classes.LargeQR}>
        <QRCode
          size={400}
          
          value={formUrl}
          logoImage={customLogo}
        />
      </div>
      <div className="css-joa6mv">
        <div className="css-1xhyf49">
          <h1 className="css-u6codq">Your QR code</h1>
        </div>
        <hr />
      </div>
      <div className="css-13p1bsc">
        <div className="css-7xto2p">
          <div className="css-cucqy9">
            {customLogo ? (
              <QRCode
                size={200}
                value={formUrl}
                logoImage={customLogo}
              />
            ) : (
              false
            )}
          </div>
        </div>
        <div className={classes.Save}>
          <h2 className="css-lojbp">Save your code</h2>
          <div className="css-uq1pv2">
            Download and save the QR code you can use this for subsequent
            check-ins. Or just return to this link!
          </div>
          <div className="css-vurnku">
            <button
              className="Button DarkButton"
              onClick={() => saveData(url, customLogo, decoded)}
            >
              Download QR code
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCode;
