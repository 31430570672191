import React from "react";
import "./EditUser.css";
import "../../../../sharedStyle/Shared.css";
import Context from "../../../../context/Context";
import { jsUcfirst } from "../../../../helpers/helpers";
import TextField from "@material-ui/core/TextField";
import moment from "moment"
import { useHistory, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
function EditVenue(props) {
  const { envUrl, userData } = React.useContext(Context);
  const history = useHistory();
  const params = useParams();
  const alert = useAlert();
  const [firstName, setFirstName] = React.useState(props.data.first_name);
  const [lastName, setLastName] = React.useState(props.data.last_name);
  const [phone, setPhone] = React.useState(props.data.phone);
  const [email, setEmail] = React.useState(props.data.email);
  const [vax, setVax] = React.useState(props.data.vax);
  const [vaxDate, setVaxDate] = React.useState(moment(props.data.vaxDate).format('YYYY-MM-DD'));
  const [booster, setBooster] = React.useState(props.data.booster);

  const Delete = () => {
    if (!window.confirm("Are you really sure you want to delete?"))
      return false;
    fetch(envUrl + "/php/formUser/delete/deleteFormUser.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "success") alert.error(data.result);
        else {
          history.push("/users");
        }
      })
      .catch((error) => {
        alert.error(JSON.stringify(error));
        console.error("Error:", error);
      });
  };

  const update = () => {
    //console.log(auto);
    fetch(envUrl + "/php/formUser/update/updateFormUser.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.id,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        booster: booster,
        vax: vax,
        vaxDate: moment(vaxDate).isValid() ? moment(vaxDate).format('YYYY-MM-DD') : null,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert.error(data.result);
        else {
          alert.success("Update complete!");
          props.setData({
            id: props.id,
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            email: email,
            booster: booster,
            vaxDate: vaxDate,
            vax: vax,
          });
        }
      })
      .catch((error) => {
        alert.error(error);
        console.error("Error:", error);
      });
  };

  return (
    <main className="EditVenue">
      <div className="EditHeading">
        <div className="EditHeading">
          <h1 className="Heading">
            Edit {props.data.first_name + "'s"} details{" "}
          </h1>
        </div>
        <hr />
      </div>
      <div className="EditFormContainer">
        <div className="AboutVenue">
          <h3 className="css-1yb0tvj">The basics</h3>
          <div className="css-lkrid6">
            Your users basic info can be updated here.
          </div>
        </div>
        <div className="VenueFeildsContainer">
          <div className="VenueFeildsContainerInner">
            <div className="VenueFeilds">
              <div className="EditFeildContainer">
                <label for="name" className="Label">
                  First name
                </label>
                <input
                  name="name"
                  placeholder="First name of your user/patron"
                  className="Input"
                  value={firstName}
                  onChange={(x) => setFirstName(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label for="name" className="Label">
                  Last name
                </label>
                <input
                  name="name"
                  placeholder="Last name of your user/patron"
                  className="Input"
                  value={lastName}
                  onChange={(x) => setLastName(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label for="description" className="Label">
                  Email address
                </label>
                <input
                  name="Email address"
                  placeholder="e.g john@gmail.com"
                  className="Input"
                  value={email}
                  onChange={(x) => setEmail(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label for="description" className="Label">
                  Phone number
                </label>
                <input
                  name="Phone number"
                  placeholder="e.g +61 425198054"
                  className="Input"
                  value={phone}
                  onChange={(x) => setPhone(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label for="description" className="Label">
                  Vaccine
                </label>
                <input
                  name="Vaccine"
                  placeholder="e.g Phizer"
                  className="Input"
                  value={vax}
                  onChange={(x) => setVax(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label for="description" className="Label">
                Last dose on
                </label>
                <TextField
                  label="From"
                  type="date"
                  value={vaxDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(x) => {
                    setVaxDate(x.target.value);
                  }}
                />
              </div>
              <div className="EditFeildContainer">
                <label for="description" className="Label">
                  Booster
                </label>

                <select
                  name="booster"
                  className="Input"
                  onChange={(x) => setBooster(x.target.value)}
                  value={booster}
                >
                  <option value="0">0</option>
                  <option value="1">1st</option>
                  <option value="2">2nd</option>
                  <option value="3">3rd</option>
                  <option value="4">4th</option>
                </select>
              </div>
            </div>

            <div className="UpdateContainer">
              <button onClick={() => update()} className="Button DarkButton">
                Update settings
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="EditFormContainer">
        <aside className="AboutVenue">
          <h3 className="css-1yb0tvj">
            Delete {props.data.first_name + " " + props.data.last_name}{" "}
          </h3>
          <div className="css-lkrid6">
            Once you delete , there is no going back. Make sure you are certain!
          </div>
        </aside>
        <div className="VenueFeildsContainer">
          <div className="VenueFeildsContainerInner">
            <div className="VenueFeilds">
              <div className="DeleteContainer">
                <label className="Label">Delete </label>
                <div className="Button LightButton" onClick={Delete}>
                  Delete
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default EditVenue;
