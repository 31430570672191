import React from "react";
import classes from "./uploadPhoto.module.css";
import Compress from "compress.js";
import Context from "../../../../context/Context";

function isFileImage(file) {
  return file && file["type"].split("/")[0] === "image";
}

function UploadPhoto(props) {
  const { userData, envUrl } = React.useContext(Context);

  const getPhotoData = (f) => {
    const compress = new Compress();
    const files = [...f];
    if (!isFileImage(files[0])) return alert("Must be an image");
    compress
      .compress(files, {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      })
      .then((data) => {
        uploadPhoto(data[0].data, props.fuid);
      });
  };

  const uploadPhoto = (img, fuid) => {
    fetch(envUrl + "/php/formUser/uploadPhoto.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        img: img,
        id: fuid,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          props.setPhoto(
            envUrl + "/formUserPhoto/" + fuid + ".jpg?" + Date.now()
          );
          alert("upload complete!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <label className={classes.PhotoIput}>
      <input
        onChange={(x) => getPhotoData(x.target.files)}
        style={{ display: "none" }}
        type="file"
        accept="image/*;capture=camera"
      ></input>{" "}
      <i className="fas fa-camera"></i>{" "}
    </label>
  );
}

export default UploadPhoto;
