import React from "react";
import classes from "./YourCategory.module.css";
import "../../sharedStyle/Shared.css";
import QRCode from "../../sharedComponents/qrcode/QRCode";
import { useParams } from "react-router-dom";
import Context from "../../context/Context";
import Enquire from "../../sharedComponents/enquire/Enquire";

function YourCategory(props) {
  const { userData, setShowMenuButton, enquire, envUrl } = React.useContext(
    Context
  );
  const { type, id } = useParams();
  
  return (
    <div className="Page">
      {enquire ? <Enquire /> : false}
      <div className={classes.YourCategory}>
        <QRCode data={{ id: 1, name: "ree", auto: 1 }} type={type} id={id} />
      </div>
    </div>
  );
}

export default YourCategory;
