import React from "react";
import "./App.css";
import CustomQR from "./pages/customCode/CustomQR";
import CustomHardCard from "./pages/customHardCard/CustomHardCard";
import Header from "./sharedComponents/header/Header";
import Footer from "./sharedComponents/footer/Footer";
import SignUp from "./pages/signUp/SignUp";
import SignIn from "./pages/signIn/SignIn";
import CategoryList from "./pages/categoryList/CategoryList";
import LocationsList from "./pages/locationsList/LocationsList";
import YourForm from "./pages/yourForm/YourForm";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import NewForm from "./pages/newForm/NewForm";
import FillForm from "./pages/fillForm/FillForm";
import Settings from "./pages/settings/Settings";
import AddCategory from "./pages/addCategory/AddCategory";
import Faq from "./pages/faq/Faq";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import Contact from "./pages/contact/Contact";
import Locations from "./pages/locations/Locations";
import NewLocation from "./pages/newLocation/NewLocation";
import YourLocation from "./pages/yourLocation/YourLocation";
import FormsList from "./pages/formsList/FormsList";
import FormUsers from "./pages/formUsers/FormUsers";
import FormUser from "./pages/formUser/FormUser";
import Home from "./pages/home/Home";
import Hardcards from "./pages/hardcards/Hardcards";
import Vaxtierreport from "./pages/tierreport/Tierreport";
import Vaxreport from "./pages/vaxreport/vaxreport";

import UserMenu from "./sharedComponents/userMenu/UserMenu";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import Context from "./context/Context";
import { checkLogin } from "./helpers/requests";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import YourCategory from "./pages/yourCategory/YourCategory";
import CategoryForm from "./pages/categoryForm/CategoryForm";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

function App() {
  const { loggedIn, showMenu, envUrl, setLoggedIn, setUserData } =
    React.useContext(Context);
  const loggedSuc = () => {
    setUserData(JSON.parse(window.sessionStorage.getItem("user")));
    setLoggedIn(true);
  };
  const loggedFailed = () => {
    setLoggedIn(false);
  };
  React.useEffect(() => {
    console.log("erer");
    checkLogin(envUrl + "/php/checkLogin.php", loggedSuc, loggedFailed);
  }, []);
  const [showHeader, setShowHeader] = React.useState(true);
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <div className="App">
        {showHeader ? <Header /> : false}
        {showMenu ? <UserMenu /> : false}
        <div className="Main">
          <Switch>
            <Route
              exact
              path="/fill"
              render={(props) => {
                setShowHeader(false);
                return <FillForm />;
              }}
            />
            <Route exact path="/forms" render={(props) => <CategoryForm />} />
            <Route
              exact
              path="/"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Redirect to="/home" /> : <SignIn />;
              }}
            />
            <Route
              path="/signin"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Redirect to="/home" /> : <SignIn />;
              }}
            />
            <Route
              path="/yourc/:type/:id/:name"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? (
                  <YourCategory
                    type={props.match.params.type}
                    id={props.match.params.id}
                  />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              path="/yourf/:type/:id/:name"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? (
                  <YourForm
                    type={props.match.params.type}
                    id={props.match.params.id}
                  />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              path="/yourl/:type/:id/:name"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? (
                  <YourLocation
                    type={props.match.params.type}
                    id={props.match.params.id}
                  />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              path="/yourc/:id"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? (
                  <FormsList id={props.match.params.id} />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              path="/signup"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Redirect to="/home" /> : <SignUp />;
              }}
            />
            <Route
              path="/forgotpassword"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Redirect to="/home" /> : <ForgotPassword />;
              }}
            />
            <Route
              path="/home"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <CategoryList /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/register/:type/:id"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? (
                  <NewForm
                    type={props.match.params.type}
                    id={props.match.params.id}
                  />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              path="/addcategory"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <AddCategory /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/settings"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Settings /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/locations"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <LocationsList /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/addlocation"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <NewLocation /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/users"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <FormUsers /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/hardcards"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Hardcards /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/vaxreport"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Vaxreport /> : <Redirect to="/signin" />;
              }}
            />
                        <Route
              path="/vaxtierreport"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <Vaxtierreport /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/youru/:type/:id"
              render={(props) => {
                setShowHeader(true);
                return loggedIn ? <FormUser /> : <Redirect to="/signin" />;
              }}
            />
            <Route
              path="/faq"
              render={(props) => {
                setShowHeader(true);
                return <Faq />;
              }}
            />
            <Route
              path="/terms"
              render={(props) => {
                setShowHeader(true);
                return <Terms />;
              }}
            />
            <Route
              path="/privacy"
              render={(props) => {
                setShowHeader(true);
                return <Privacy />;
              }}
            />
            <Route
              path="/contact"
              render={(props) => {
                setShowHeader(true);
                return <Contact />;
              }}
            />
            <Route
              path="/yourqr"
              render={(props) => {
                setShowHeader(true);
                return <CustomQR />;
              }}
            />
            <Route
              path="/yourhardcard"
              render={(props) => {
                setShowHeader(true);
                return <CustomHardCard />;
              }}
            />
          </Switch>
        </div>
        <Footer />
      </div>
    </AlertProvider>
  );
}

export default App;
