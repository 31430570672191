import React from "react";
import "../../sharedStyle/Shared.css";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom"
import Context from "../../context/Context";

function VenuesEvents(props) {
  const {type, id} = useParams();
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("nothing");
  const [industry, setIndustry] = React.useState("nothing");
  const { userData, setShowMenuButton, envUrl } = React.useContext(Context);
  setShowMenuButton(false)
  let history = useHistory();
  

  const createVenueEvent = () => {
    if (!name) {
      alert("You need to enter a name.");
      return false;
    }
    fetch(envUrl+"/php/createVenueEvent.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        typeId: id,
        name: name,
        address: address,
        industry: industry,
        token: userData.token
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.result !== "success") alert(data.result);
        else {
            history.push('/yourf/'+type+'/'+data.data+'/'+name)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="Page">
      <div className="SignUpInner">
        <h1 className="Heading">Register {type}</h1>
        <hr />

        <div className="SignInputContainer">
          <label for="email" className="Label">
            {type} name*
          </label>
          <input type="text" className="Input" value={name} onChange={(x)=>setName(x.target.value)}/>
        </div>
       
        <div className="SignButtons">
          <button onClick={createVenueEvent} className="Button DarkButton">
            Submit
          </button>
          <Link to="/home" className="Button LightButton">
            Cancel
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VenuesEvents;
