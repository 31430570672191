import React from "react";
import "./Home.module.css"
function Home(props) {
  

  return (
    <div className="Home">
        
    </div>
  );
}

export default Home;
