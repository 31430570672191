export function validateEmail(url, fuid) {
  fetch(url+'/php/validateEmail.php', {
    method: "POST", // or 'PUT'
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fdid: fuid,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.result !== "success") alert(data.result);
    })
    .catch((error) => {
        alert(error);
    });
}

export function reSendEmail(url, fuid, token) {
  fetch(url+"/php/qrCode/sendQR.php", {
    method: "POST", // or 'PUT'
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: fuid,
      token:token,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.result !== "success") alert(data.result);
      alert(data.result);
    })
    .catch((error) => {
        alert(error);
    });
}
