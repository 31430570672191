export function validateEmail(url, fuid) {
  fetch(url+'/php/validateEmail.php', {
    method: "POST", // or 'PUT'
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fuid: fuid,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.result !== "success") alert(data.result);
    })
    .catch((error) => {
        alert(error);
    });
}
