import React from "react";
import "../../sharedStyle/Shared.css";
import Context from "../../context/Context";
import GetFormUsers from '../../sharedComponents/getFormUsers/GetFormUsers'
import classes from "./FormUsers.module.css";



function FormUsers() {
  const { userData, setShowMenuButton, envUrl } = React.useContext(Context);
 
  return (
    <div className={"Page "+classes.FormUsers}>
     <GetFormUsers type={'formUser'} search={true} data={{loc:''}} id={''} setShow={''} email={true}/>
    </div>
  );
}

export default FormUsers;
