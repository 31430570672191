import React from "react";
import "../../sharedStyle/Shared.css";
import {Link} from 'react-router-dom'
import Loading from '../../sharedComponents/loading/Loading'
import Context from "../../context/Context";

function ForgotPassword() {
  const { envUrl } = React.useContext(Context);
  const[email, setEmail] = React.useState('')
  const[load, setLoad] = React.useState(false)

  const sendEmail = () => {
    if (!email) {
      return alert("You need to enter an email");
      
    }
    setLoad(true)
    fetch(envUrl+"/php/forgotPassword.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email:email
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.result !== "success") alert(data.result);
        else {
            alert('Temporary password has been sent. Remeber to check your spam.')
        }
        setLoad(false)
      })
      .catch((error) => {
        setLoad(false)
        console.error("Error:", error);
      });
  };

  return (
    <div className="Page">
      {load ? <Loading/> : <>
      <div className="SignUpInner">
        <h1 className="Heading">Forgot your password?</h1>
        <div className="SignOption">
          Or return to{' '}
          <Link className="Link" to="/signin">
            sign in
          </Link>
          .
        </div>
          <div className="SignInputContainer">
            <label for="email" className="Label">
              Email address
            </label>
            <input type="email" name="email" className="Input" value={email}  onChange={(x)=>setEmail(x.target.value)}/>
          </div>
          <div className="SignButtons">
            <button type="submit" value="Submit" className="Button DarkButton" onClick={sendEmail}>
              Reset password
            </button>
          </div>
      </div>
      </> }
    </div>
  );
}

export default ForgotPassword;
