import React from "react";
import "./SignUp.css";
import "../../sharedStyle/Shared.css";
import Context from "../../context/Context";
import { Link } from "react-router-dom";

const ValidateEmail = (mail) => {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  alert("You have entered an invalid email address!");
  return false;
};

function SignUp(props) {
  const { setLoggedIn, setUserData, envUrl } = React.useContext(Context);

  const [email, setEmail] = React.useState("");
  const [first, setFirst] = React.useState("");
  const [last, setLast] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirm, setConfirm] = React.useState("");

  const urlParams = new URLSearchParams(window.location.search);
  let type = urlParams.get("type");

  if (type === null) {
    type = "venues";
  }

  const createUser = () => {
    if (!ValidateEmail(email)) {
      return false;
    }
    if (password !== confirm) {
      alert("Passwords dont match.");
      return false;
    }
    if (password.length < 8) {
      alert("Password must be at least 8 charatures.");
      return false;
    }
    fetch(envUrl + "/php/signup.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        firstName: first,
        lastName: last,
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          window.sessionStorage.setItem("user", JSON.stringify(data.user));
          setUserData(data.user);
          setLoggedIn(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="Page">
      <form
        className="SignUpInner"
        onSubmit={(e) => {
          e.preventDefault();
          createUser();
        }}
      >
        <h1 className="Heading">Create your account</h1>
        <div className="SignOption">
          Or{" "}
          <Link className="css-1ref0f2" to="/signin">
            sign in to your account
          </Link>
          .
        </div>
        <div className="css-oyqhxw">
          <div className="SignInputContainer">
            <label className="Label">First name</label>
            <input
              name="firstName"
              className="Input"
              value={first}
              onChange={(x) => setFirst(x.target.value)}
            />
          </div>
          <div className="SignInputContainer">
            <label className="Label">Last name</label>
            <input
              name="lastName"
              className="Input"
              value={last}
              onChange={(x) => setLast(x.target.value)}
            />
          </div>
        </div>
        <div className="SignInputContainer">
          <label className="Label">Email address</label>
          <input
            type="email"
            name="email"
            className="Input"
            value={email}
            onChange={(x) => setEmail(x.target.value)}
          />
        </div>
        <div className="SignInputContainer">
          <label className="Label">Password</label>
          <input
            type="password"
            name="password"
            placeholder="8+ characters"
            className="Input"
            value={password}
            onChange={(x) => setPassword(x.target.value)}
          />
        </div>
        <div className="SignInputContainer">
          <label className="Label">Confirm password</label>
          <input
            type="password"
            name="confirm"
            placeholder="Confirm password"
            className="Input"
            value={confirm}
            onChange={(x) => setConfirm(x.target.value)}
          />
        </div>
        <div className="SignButtons">
          <button type="submit" className="Button DarkButton" >
            Create account
          </button>
          <Link to="/" className="Button LightButton">
            Return home
          </Link>
        </div>
        <div className="SignInputContainer">
          <div className="css-lkrid6">
            By clicking sign up, you agree to our{" "}
            <Link to="/terms" className="Link">terms &amp; conditions</Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignUp;
