import React, { Suspense } from "react";
import "../../sharedStyle/Shared.css";
import Menu from "../../sharedComponents/menu/Menu";

import { useParams } from "react-router-dom";
import Context from "../../context/Context";
import Loading from "../../sharedComponents/loading/Loading";
import Enquire from "../../sharedComponents/enquire/Enquire";
import { Switch, Route } from "react-router-dom";
import Overview from "./components/overview/Overview";
import QRCode from "../../sharedComponents/customCode/CustomQR";
import HardCard from "../../sharedComponents/hardCard/HardCard";
import EditUser from "./components/EditUser/EditUser";
import Permissions from "./components/permissions/Permissions"

const ViewForms = React.lazy(() => import("./components/viewForms/ViewForms"));
const ViewForm = React.lazy(() => import("./components/viewForm/ViewForm"));



function FormUser(props) {
  const { userData, setShowMenuButton, enquire, envUrl } = React.useContext(
    Context
  );
  const [show, setShow] = React.useState("Overview");
  const [data, setData] = React.useState();
  const { type, id } = useParams();
  setShowMenuButton(true);

  const getFormUser = () => {
    console.log(id);
    fetch(envUrl + "/php/formUser/get/getFormUser.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "success") alert(data.result);
        else {
          console.log(data.data);
          setData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    getFormUser();
  }, []);

  return (
    <div className="Page">
      {enquire ? <Enquire /> : false}
      <div className="YourVenue">
        <Menu type={type} />
        {!data ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/youru/:type/:id"
                render={(props) => (
                  <Overview
                    setShow={setShow}
                    data={data}
                    type={type}
                    id={data.id}
                  />
                )}
              />
              <Route
                exact
                path="/youru/:type/:id/code"
                render={(props) => (
                  <QRCode
                    id={props.match.params.id}
                    data={data}
                    url={btoa(
                      JSON.stringify({
                        fuid: data.id,
                        uid: userData.id,
                        "2.0": "1",
                      })
                    )}
                  />
                )}
              />
              <Route
                exact
                path="/youru/:type/:id/hardcard"
                render={(props) => (
                  <HardCard
                    id={props.match.params.id}
                    editable={true}
                    data={data}
                    url={btoa(
                      JSON.stringify({
                        fuid: data.id,
                        uid: userData.id,
                        "2.0": "1",
                      })
                    )}
                  />
                )}
              />
              <Route
                exact
                path="/youru/:type/:id/viewforms"
                render={(props) => <ViewForms id={data.id} data={data} />}
              />
              <Route
                exact
                path="/youru/:type/:id/viewform/:fid"
                render={(props) => (
                  <ViewForm id={props.match.params.fid} data={data} />
                )}
              />
              <Route
                exact
                path="/youru/:type/:id/edit"
                render={(props) => (
                  <EditUser data={data} type={type} id={id} setData={setData} />
                )}
              />
              <Route
                exact
                path="/youru/:type/:id/permissions"
                render={(props) => (
                  <Permissions data={data} type={type} id={id} />
                )}
              />
            </Switch>
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default FormUser;
