import React from "react";
import "../../sharedStyle/Shared.css";
import "./Contact.css";
import Context from '../../context/Context'
var Recaptcha = require('react-recaptcha');

function Contact(props) {
    const {envUrl, setShowMenuButton} = React.useContext(Context)
  const [email, setEmail] = React.useState("");
  const [body, setBody] = React.useState("");
  const [name, setName] = React.useState("");
  const [token, setToken] = React.useState("");
  setShowMenuButton(false)

  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  };

  const sendEmail = () => {
      console.log(token)
    fetch(envUrl+"/php/sendEmail.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name, email: email, body: body, token:token}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setEmail("");
        setBody("");
        setName("");
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(error);
      });
  };

  const send = () => {
    if (!ValidateEmail(email)) return alert("sorry, invalid email.");
    if (!name) return alert("What yours name?");
    if (!body) return alert("sorry, body cant be empty.");
    sendEmail();
  };

  var recaptcha =  (value) => {
    setToken(value)
  };

  return (
    <div className="Page">
      <div className="Contact">
        <div className="HeadingContainer">
          <h1 className="Heading">Contact us!</h1>
        </div>
        <hr />
        <div id="contactUnderline" className="underLine"></div>
        <div className="email">
          <input
            placeholder="Name"
            type="text"
            name="name"
            required=""
            onChange={(x) => setName(x.target.value)}
            value={name}
          />
          <input
            className="Input"
            placeholder="Enter email"
            type="email"
            name="email"
            required=""
            value={email}
            onChange={(x) => setEmail(x.target.value)}
          />
          <textarea
            className="Input"
            placeholder="Your Message"
            type="text"
            name="message"
            value={body}
            onChange={(x) => setBody(x.target.value)}
          ></textarea>
          
        <Recaptcha sitekey="6LeDvboZAAAAAKEGzpquqLQRsFj--FntIoktpqZ3" render="explicit" verifyCallback={(x)=>recaptcha(x)} theme="light"/>
          {token ? <button className="Button DarkButton" id="submit"  onClick={send}>
            Submit
          </button> : false}
        </div>
      </div>
    </div>
  );
}

export default Contact;
