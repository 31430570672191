import React from "react";
import "../../sharedStyle/Shared.css";
import classes from "./CategoryForm.module.css";
import {Link, useHistory} from 'react-router-dom'

import Loading from "../../sharedComponents/loading/Loading";

import Context from "../../context/Context";

const Form = (props) => {
  return (
    <div className={classes.FormChoice} >
      <Link
        
        to={"/fill?id="+props.id}
      >
        {props.name}
      </Link>
    </div>
  );
};


function CategoryForm(props) {
  const history = useHistory();
  const { envUrl } = React.useContext(Context);
  const [forms, setForms] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [name, setName] = React.useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const onLoad = () => {
    setLoad(true);
    fetch(envUrl + "/php/getCategoryForms.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (data.result === "success") {
            setForms(data.data)
            setName(data.name)
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    onLoad();
  }, []);

  return (
    <main className="Page">
      <div className="Form">
        <div className="EditHeading">
          <div className="EditHeading">
            <h1 className={load ? classes.FillHeading : ""}>Pick a waiver</h1>
          </div>
          <hr />
        </div>
        { load ? (
          <Loading />
        ) : (
          <select  defaultValue={0} className={classes.FormChoice} onChange={(x)=>history.push(x.target.value)}>
            <option value={0} disabled>Select waiver</option>
            {
            forms.map((x)=><option value={"/fill?id="+x.id} >{x.name}</option>)
            }
          </select>
        )}
      </div>
    </main>
  );
}

export default CategoryForm;
