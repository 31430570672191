import React from "react";
import "./Permissions.module.css";
import "../../../../sharedStyle/Shared.css";
import moment from "moment";
import Context from "../../../../context/Context";
import classes from "./Permissions.module.css";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const SitePermissions = (props) => {
  const [flags, setFlags] = React.useState(props.flags);
  const { userData, setEnquire, envUrl } = React.useContext(Context);
  const onUpdate = () => {
    let updateAll = false;
    if (props.master) updateAll = true;
    fetch(envUrl + "/php/flags/update/updateFlagsUser.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fuid: props.fuid,
        flags: flags,
        issued: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        locationID: props.locationID,
        updateAll: updateAll,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "success") alert("updated");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="VenueFeildsContainerInner">
      <div className="VenueFeildsHeader">
        <h3 className="css-154x21u">{props.name}</h3>
      </div>
      <div className={classes.checkBoxContainer}>
        {Object.keys(flags).map((x) => (
          <label className={classes.flagCheck}>
            <div className="css-vurnku">
              <label className="container">
                <input
                  type="checkbox"
                  id="destroyRecords"
                  name="destroyRecords"
                  onChange={() =>
                    setFlags({
                      ...flags,
                      [x]: {
                        ...flags[x],
                        value: !parseInt(flags[x].value) ? 1 : 0,
                      },
                    })
                  }
                  defaultChecked={parseInt(flags[x].value)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <span className={classes.checkLabel}>{flags[x].flag_type}</span>
          </label>
        ))}
      </div>

      <div className="UpdateContainer">
        <button className="Button DarkButton" onClick={onUpdate}>
          Update permissions
        </button>
      </div>
    </div>
  );
};

const Master = (props) => {
  const [flags, setFlags] = React.useState(props.flags);
  const { userData, setEnquire, envUrl } = React.useContext(Context);
  const [selected, setSelected] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const onUpdate = () => {
    console.log(value);
    if (value === null) return alert("You need to select a status");
    fetch(envUrl + "/php/flags/update/updateFlagsUser.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fuid: props.fuid,
        flags: selected,
        issued: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        updateAll: true,
        value: value,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "success") {
          props.callback();
          alert("updated");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="VenueFeildsContainerInner">
      <div className="VenueFeildsHeader">
        <h3 className="css-154x21u">
          <b>{props.name}</b>
        </h3>
      </div>
      <div className={classes.masterContainer}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={Object.values(flags)}
            getOptionLabel={(option) => option.flag_type}
            // defaultValue={[top100Films[13]]}
            className={classes.multiSelect}
            onChange={(event, newValue) => setSelected(newValue)}
            placeholder="Permissions"
            renderInput={(params) => (
              <TextField
                //className={classes.multiSelectText}
                {...params}
                variant="standard"
                //label="Multiple values"
                placeholder="Permissions"
              />
            )}
          />
          <FormHelperText>Select permissions</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={value}
            onChange={(x) => setValue(x.target.value)}
          >
            <MenuItem value={null}></MenuItem>
            <MenuItem value={0}>Unchecked</MenuItem>
            <MenuItem value={1}>Checked</MenuItem>
          </Select>
          <FormHelperText>Checked or unchecked</FormHelperText>
        </FormControl>
      </div>

      <div className="UpdateContainer">
        <button className="Button DarkButton" onClick={onUpdate}>
          Update permissions
        </button>
      </div>
    </div>
  );
};

function ViewForm(props) {
  const { userData, setEnquire, envUrl } = React.useContext(Context);
  const [flags, setFlags] = React.useState([]);

  const onLoad = () => {
    setFlags([]);
    fetch(envUrl + "/php/flags/get/getFlagsUser.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.id,
        token: userData.token,
        type: props.type,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "success")
          setFlags(JSON.parse(JSON.stringify(data.data)));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    onLoad();
  }, []);

  return (
    <main className="EditVenue ViewForm">
      <div className="EditHeading">
        <div className="EditHeading">
          <h1 className="Heading">
            {" "}
            {props.data.first_name}'s site permissions
          </h1>
        </div>
        <hr />
      </div>
      {flags.length ? (
        <Master
          callback={onLoad}
          fuid={props.id}
          master={true}
          name={"Change for all sites"}
          flags={flags[0].flags}
        />
      ) : (
        false
      )}
      {flags.map((x) => (
        <SitePermissions
          fuid={props.id}
          locationID={x.location_id}
          name={x.name}
          flags={x.flags}
        />
      ))}
    </main>
  );
}

export default ViewForm;
