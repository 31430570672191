import React from "react";
import "./Menu.css";
import "../../sharedStyle/Shared.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPlusCircle,
  faEdit,
  faQrcode,
  faEye,
  faFileAlt,
  faChartLine,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import Context from "../../context/Context";
import Enquire from "../enquire/Enquire";
import { useHistory, useLocation } from "react-router-dom";

function Menu(props) {
  const { loggedIn, menuSmall, userData, setEnquire, envUrl } =
    React.useContext(Context);
  const history = useHistory();
  const [location] = React.useState(useLocation());
  return (
    <div className={menuSmall ? "Menu smallMenu" : "Menu"}>
      <div className="MenuInner">
        <a
          className="MenuOption"
          title="Overview"
          onClick={() => history.push(location.pathname)}
        >
          <FontAwesomeIcon style={{ fontSize: 16 }} icon={faHome} />
          {menuSmall ? false : <span>Overview</span>}
        </a>

        {props.type === "forms" ? (
          <a
            aria-current="page"
            className="MenuOption"
            title="Edit venue"
            onClick={() => history.push(location.pathname + "/edit")}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEdit} />
            {menuSmall ? false : <span>Edit</span>}
          </a>
        ) : (
          false
        )}

        {props.type === "forms" ? (
          <a
            className="MenuOption"
            title="QR code"
            onClick={() => history.push(location.pathname + "/code")}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faQrcode} />
            {menuSmall ? false : <span>QR code</span>}
          </a>
        ) : (
          false
        )}

        {props.type === "forms" ? (
          <a
            className="MenuOption"
            title="Add category"
            onClick={() => history.push(location.pathname + "/add")}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPlusCircle} />
            {menuSmall ? false : <span>Add form</span>}
          </a>
        ) : (
          false
        )}
        {props.type === "categories" ? (
          <a
            className="MenuOption"
            title="Add category"
            onClick={() => history.push(location.pathname + "/add")}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPlusCircle} />
            {menuSmall ? false : <span>Add category</span>}
          </a>
        ) : (
          false
        )}
        {props.type === "locations" ? (
          <a
            className="MenuOption"
            title="Add location"
            onClick={() => history.push(location.pathname + "/add")}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPlusCircle} />
            {menuSmall ? false : <span>Add location</span>}
          </a>
        ) : (
          false
        )}
      </div>
    </div>
  );
}

export default Menu;

/* <a
          className="MenuOption"
          title="Analytics"
          onClick={() => {
            if (userData.permissions > 1) props.setShow("Analytics");
            else setEnquire(true);
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChartLine} />
          {menuSmall ? false : <span>Analytics</span>}
        </a>*/
