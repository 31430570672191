import React from "react";
import "./Overview.css";
import "../../../../sharedStyle/Shared.css";
import Context from "../../../../context/Context";
import GetFormUsers from "../../../../sharedComponents/getFormUsers/GetFormUsers";
import defualtUserImg from "../../../../assets/default-user-image.png";
import UploadPhoto from "../uploadPhoto/uploadPhoto";
import { useHistory, useLocation } from "react-router-dom";
import EditPhoto from "../EditPhoto/EditPhoto";
import moment from "moment";

let loadImage = function (url, handler) {
  var image = new Image();
  var url_image = url + "?" + Date.now();
  image.onload = function () {
    handler(url_image);
  };
  image.onerror = function () {
    handler(defualtUserImg);
  };
  image.src = url_image;
};

function Overview(props) {
  const { userData, envUrl } = React.useContext(Context);
  const history = useHistory();
  const [location] = React.useState(useLocation());
  const [photo, setPhoto] = React.useState(defualtUserImg);
  React.useState(() => {
    loadImage(envUrl + "/formUserPhoto/" + props.id + ".jpg", setPhoto);
  }, []);

  return (
    <div className="EditVenue">
      <div className="css-joa6mv">
        <div className="css-1xhyf49 ">
          <h1 className="css-u6codq">{props.data.first_name}'s overview</h1>
        </div>
        <hr />
      </div>

      <div className="VenueFeildsContainerInner">
        <div className="VenueFeildsHeader">
          <h3 className="css-154x21u">Personal details</h3>
        </div>
        <div className="overviewInner">
          <div className="OverViewInnerCol">
            <div className="card-meta">
              <div className="card-label">Full name</div>
              <div className="css-vurnku">
                {props.data.first_name + " " + props.data.last_name}
              </div>
            </div>
            <div className="card-meta">
              <div className="card-label">Email address</div>
              <div className="css-vurnku">{props.data.email}</div>
            </div>
            <div className="card-meta">
              <div className="card-label">Phone number</div>
              <div className="css-vurnku">{props.data.phone}</div>
            </div>
            <div className="card-meta">
              <div className="card-label">Vaccine</div>
              <div className="css-vurnku">{props.data.vax || "unknown"}</div>
            </div>
            <div className="card-meta">
              <div className="card-label">Last dose</div>
              <div className="css-vurnku">
                {props.data.vaxDate
                  ? moment(props.data.vaxDate).format("YYYY-MM-DD")
                  : "unknown"}
              </div>
            </div>
            <div className="card-meta">
              <div className="card-label">Booster</div>
              <div className="css-vurnku">
                {props.data.booster || "unknown"}
              </div>
            </div>
            <div className="css-4cffwv"></div>
          </div>
          <div className="formUserImgContainer">
            <UploadPhoto setPhoto={setPhoto} fuid={props.id} />
            <EditPhoto setPhoto={setPhoto} photo={photo} fuid={props.id} />
            <img className="formUserImg" src={photo} />
          </div>
        </div>

        <div className="css-y0uto3">
          <a
            className="Button DarkButton"
            onClick={() => history.push(location.pathname + "/edit")}
          >
            Edit user
          </a>
        </div>
      </div>
      <GetFormUsers
        title={"Filled forms"}
        type={"userForms"}
        data={props.data}
        id={props.id}
        setShow={props.setShow}
        add={false}
        export={false}
      />

      <GetFormUsers
        title={"Site check-ins"}
        type={"userCheckins"}
        data={props.data}
        id={props.id}
        setShow={props.setShow}
        add={false}
        export={false}
      />
    </div>
  );
}

export default Overview;
