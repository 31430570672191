import React from "react";
import "../../sharedStyle/Shared.css";
import "./Complete.css";

const amendUrl = (s) => {
  var prefix = "https://";
  if (s.substr(0, prefix.length) !== prefix) 
    s = prefix + s;
    
  return s;
};

function Complete(props) {
  console.log(props);
  const time = new Date();
  return (
    <div className="Complete">
      <div className="animation-ctn">
        <div className="icon icon--order-success svg">
          <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
            <g fill="none" stroke="#006e68" stroke-width="2">
              <circle
                cx="77"
                cy="77"
                r="72"
                style={{
                  strokeDasharray: "480px, 480px",
                  strokeDashoffset: 960,
                }}
              ></circle>
              <circle
                id="colored"
                fill="#006e68"
                cx="77"
                cy="77"
                r="72"
                style={{
                  strokeDasharray: "480px, 480px",
                  strokeDashoffset: 960,
                }}
              ></circle>
              <polyline
                className="st0"
                stroke="#fff"
                stroke-width="10"
                points="43.5,77.8 63.7,97.9 112.2,49.4 "
                style={{
                  strokeDasharray: "100px, 100px",
                  strokeDashoffset: 200,
                }}
              />
            </g>
          </svg>
        </div>
      </div>
      <h3>Waiver successfully completed!</h3>
      <div className="CompleteDetails">
        <b>Date:</b>
        {time.toLocaleDateString()}
        <br />
        <b>Time:</b>
        {time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds()}
      </div>
      <div className="Socials">
        {props.socials.facebook ? (
          <a href={amendUrl(props.socials.facebook)}>
            <i className="fab fa-facebook-square"></i>
          </a>
        ) : (
          false
        )}
        {props.socials.instagram ? (
          <a href={amendUrl(props.socials.instagram)}>
            <i className="fab fa-instagram-square"></i>
          </a>
        ) : (
          false
        )}
        {props.socials.website ? (
          <a href={amendUrl(props.socials.website)}>
            <i className="far fa-window-maximize"></i>
          </a>
        ) : (
          false
        )}
      </div>
    </div>
  );
}

export default Complete;
