import React from "react";
import classes from "./Dependants.module.css";
import Button from "@material-ui/core/Button";
import {
  Add,
  DeleteForever,
  NavigateNext,
  ArrowUpward,
} from "@material-ui/icons";
import Context from "../../../context/Context";

function Dependants(props) {
  const { envUrl } = React.useContext(Context);
  const [dependants, setDependants] = React.useState([]);

  const onPost = (data) => {
    fetch(envUrl + "/php/dependants/insert/insertDependants.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dependants: dependants,
        fdid: props.fdid,
        fid: props.fid
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        props.callBack(true)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateDependants = (index, value) => {
    const updateArr = [...dependants];
    updateArr[index] = value;
    setDependants(updateArr);
  };

  const removeDependant = (index) => {
    const updateArr = [...dependants];
    updateArr.splice(index, 1);
    setDependants(updateArr);
  };

  const dependant = (i) => (
    <div className={classes.Dependant}>
      <label className="Label">Dependant {i + 1} full name</label>
      <div className={classes.nameContainer}>
        <input
          type="text"
          className={"Input " + classes.input}
          value={dependants[i]}
          onChange={(x) => updateDependants(i, x.target.value)}
        />
        <Button
        
          variant="contained"
          color="default"
          endIcon={<DeleteForever />}
          onClick={() => removeDependant(i)}
        >
          Remove
        </Button>
      </div>
    </div>
  );

  const addDependant = () => {
    const updateArr = [...dependants];
    updateArr.push("");
    setDependants(updateArr);
  };

  React.useEffect(() => {}, []);

  return (
    <div className={classes.Container}>
      <Button
        variant="contained"
        color="default"
        className=""
        endIcon={<Add />}
        onClick={addDependant}
      >
        Add dependant
      </Button>
      <div className={classes.innerContainer}>
        {dependants.map((el, i) => dependant(i))}
      </div>
      <div className={classes.footerContainer}>
        {dependants.length ? (
          <Button
            variant="outlined"
            color="default"
            className=""
            endIcon={<ArrowUpward />}
            onClick={onPost}
          >
            Submit
          </Button>
        ) : (
          false
        )}
        <Button
          variant="outlined"
          color="default"
          className=""
          endIcon={<NavigateNext />}
          onClick={() => props.callBack(true)}
        >
          Skip
        </Button>
      </div>
    </div>
  );
}

export default Dependants;
