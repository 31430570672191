import React from "react";
import "../../sharedStyle/Shared.css";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom"
import Context from "../../context/Context";

function AddCategory(props) {
  const [name, setName] = React.useState("");  
  const { userData, setShowMenuButton, envUrl } = React.useContext(Context);
  setShowMenuButton(false)
  let history = useHistory();
  

  const addCategory = () => {
    if (!name) {
      alert("You need to enter a name.");
      return false;
    }
    fetch(envUrl+"/php/addCategory.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        token: userData.token
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.result !== "success") alert(data.result);
        else {
            history.push('/home')
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="Page">
      <div className="SignUpInner">
        <h1 className="Heading">Add category</h1>
        <hr />

        <div className="SignInputContainer">
          <label for="email" className="Label">
          category name*
          </label>
          <input type="text" className="Input" placeholder="e.g. Job sites" value={name} onChange={(x)=>setName(x.target.value)}/>
        </div>
       
        <div className="SignButtons">
          <button onClick={addCategory} className="Button DarkButton">
            Submit
          </button>
          <Link to="/home" className="Button LightButton">
            Cancel
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
