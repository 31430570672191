import React from "react";
import "../../sharedStyle/Shared.css";
import "./UserMenu.css";
import Context from "../../context/Context";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import avatar from "../../assets/avatar.png";

function UserMenu() {
  const { setLoggedIn, setShowMenu, userData } = React.useContext(Context);
  let history = useHistory();
  return (
    <div className="UserMenu">
      <div className="MenuHeader">
        <div className="Avatar">
          <img
            alt="luke daniels"
            src={avatar}
            height="40"
            width="40"
            className="MenuImg"
          />
        </div>
        <Link to="/settings" className="MenuInfo Link">
          <div className="css-vurnku">
            {userData.firstName + " " + userData.lastName}
          </div>
          <div className="css-vurnku">{userData.email}</div>
        </Link>
        <FontAwesomeIcon
          style={{
            fontSize: 35,
            padding: 5,
            paddingBottom: 15,
            cursor: "pointer",
          }}
          icon={faTimes}
          onClick={() => setShowMenu(false)}
        />
      </div>

      <Link
        className="MenuOption"
        to="/settings"
        onClick={() => setShowMenu(false)}
      >
        Settings
      </Link>
      <a className="MenuOption" href="https://trac-qr.com/scanner">
        Scanner
      </a>
      <Link className="MenuOption" to="/locations">
        Locations
      </Link>
      <Link className="MenuOption" to="/home">
        Forms
      </Link>
      <Link className="MenuOption" to="/users">
        Users
      </Link>
      <div>
        <div
          className="MenuOption"
          onClick={(e) => {
            window.sessionStorage.clear();
            setLoggedIn(false);
            setShowMenu(false);
          }}
        >
          Sign out
        </div>
      </div>
    </div>
  );
}

export default UserMenu;
