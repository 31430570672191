const headers = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  OPTIONS: "",
};

export function post(url, data) {
  return fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  }).then((response) => response);
}

export function get(url) {
  return fetch(url, {
    method: "GET",
    headers,
  }).then((response) => response.json());
}

export function checkLogin(url, callbackSuccess, callbackError) {
  function get_token() {
    if (window.sessionStorage.getItem("user"))
      return JSON.stringify({
        token: JSON.parse(window.sessionStorage.getItem("user")).token,
      });
    return false;
  }

  fetch(url, {
    method: "POST",
    headers,
    body: get_token(),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.result !== "success") {
        callbackError();
      } else {
        callbackSuccess();
      }
    })
    .catch((error) => {
      callbackError();
      console.error("Error:", error);
    });
}
