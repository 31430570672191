import React, { Suspense } from "react";
import "./YourForm.css";
import "../../sharedStyle/Shared.css";
import Menu from "../../sharedComponents/menu/Menu";
import { useParams } from "react-router-dom";
import Context from "../../context/Context";
import Loading from "../../sharedComponents/loading/Loading";
import Enquire from "../../sharedComponents/enquire/Enquire";
import { Switch, Route } from "react-router-dom";
const FormBuilder = React.lazy(() =>
  import("./components/formBuilder/FormBuilder")
);
const ViewForm = React.lazy(() => import("./components/viewForm/ViewForm"));
const AddPatron = React.lazy(() =>
  import("../../sharedComponents/addPatron/AddPatron")
);
const EditVenue = React.lazy(() => import("./components/editForm/EditForm"));
const Overview = React.lazy(() =>
  import("../../sharedComponents/overview/Overview")
);
const QRCode = React.lazy(() => import("../../sharedComponents/qrcode/QRCode"));
const Analytics = React.lazy(() => import("./components/analytics/Analytics"));

function YourVenue(props) {
  const { userData, setShowMenuButton, enquire, envUrl } = React.useContext(
    Context
  );
  const [show, setShow] = React.useState("Overview");
  const [data, setData] = React.useState();
  const { type, id } = useParams();
  setShowMenuButton(true);
  const getVenueEvent = () => {
    console.log(id);
    fetch(envUrl + "/php/getVenueEvent.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "success") alert(data.result);
        else {
          console.log(data.data);
          setData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    getVenueEvent();
  }, []);

  return (
    <div className="Page">
      {enquire ? <Enquire /> : false}
      <div className="YourVenue">
        <Menu setShow={setShow} type={type} />
        {!data ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/yourf/:type/:id/:name"
                render={(props) => (
                  <Overview
                    setShow={setShow}
                    data={data}
                    type={type}
                    id={data.id}
                  />
                )}
              />
              <Route
                exact
                path="/yourf/:type/:id/:name/code"
                render={(props) => <QRCode data={data} type={type} id={id} />}
              />
              <Route
                exact
                path="/yourf/:type/:id/:name/formbuilder"
                render={(props) => <FormBuilder id={data.id} data={data} />}
              />
              <Route
                exact
                path="/yourf/:type/:id/:name/viewform"
                render={(props) => <ViewForm id={data.id} data={data} />}
              />
              <Route
                exact
                path="/yourf/:type/:id/:name/edit"
                render={(props) => (
                  <EditVenue
                    data={data}
                    type={type}
                    id={id}
                    setData={setData}
                  />
                )}
              />
              <Route
                exact
                path="/yourf/:type/:id/:name/analytics"
                render={(props) => (
                  <Analytics data={data} type={type} id={id} />
                )}
              />
              <Route
                exact
                path="/yourf/:type/:id/:name/add"
                render={(props) => (
                  <AddPatron
                    setShow={setShow}
                    data={data}
                    type={type}
                    id={id}
                  />
                )}
              />
            </Switch>
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default YourVenue;
