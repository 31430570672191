import React from "react";
import './Enquire.css'
import "../../sharedStyle/Shared.css";
import Context from '../../context/Context'
import {Link} from 'react-router-dom'

function Enquire(props) {
    const{setEnquire} = React.useContext(Context)
  return (
    <div className="Enquire">
        <div className="EnquireContainer">
            <h4>Upgrade TRAQRC</h4>
            <p>Choose what you want to ask. Ask for postcodes, medicare number, white card number, and whatever you can think of. Make these custom fields compulsory or optional. Upload custom logos, and access analytics!</p>
            <Link className="Button DarkButton" to="/contact">Enquire</Link>
            <button className="Button LightButton" onClick={()=>setEnquire(false)}>Exit</button>
        </div>
    </div>
  );
}

export default Enquire;
