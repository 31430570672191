import React from "react";
import { QRCode } from "react-qrcode-logo";
import Context from "../../context/Context";
import { decode, getQueryVariable } from "../../helpers/helpers";
import { validateEmail, reSendEmail } from "./helpers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {
  BlobProvider,
  PDFViewer,
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  Image as Img,
} from "@react-pdf/renderer";
//import { Document as Doc, Page as P } from "react-pdf";

//css imports
import classes from "./HardCard.module.css";
import "../../sharedStyle/Shared.css";

//img imports
import logo from "../../assets/qr.png";
import defualtUserImg from "../../assets/default-user-image.png";
import logoWSL from "../../assets/wsl-logo.png";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    position: "absolute",
    minHeight: "100%",
    minWidth: "100%",
  },
  footerDate: {
    marginTop: 10,
    fontSize: 55,
    paddingLeft: 20,
  },
  userPhoto: {
    position: "absolute",
    top: 70,
    right: 0,
    height: "70%",
    zIndex: -1,
  },
  overlay: {
    position: "absolute",
    top: 30,
    bottom: 60,
    right: 30,
    left: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor:"#fff",
    zIndex: 0,
  },
  header: {
    minWidth: "100%",
    height: 100,
    fontSize: 80,
    paddingLeft: 20,
  },
  footer: {
    minWidth: "100%",
    height: 80,
  },
  footerBottom: {
    minWidth: "100%",
    height: 60,
  },
  nameContainer: {
    minWidth: "100%",
    //height: 190,
    fontSize: 55,
    marginTop: 100,
  },
  heading: { minWidth: "100%", height: "100%" },
  underline: {
    position: "absolute",
    top: 20,
    height: 40,
    backgroundColor: "#fff",
    minWidth: "80%",
  },
  underlineFooter: {
    position: "absolute",
    top: 40,
    height: 40,
    backgroundColor: "#fff",
    minWidth: "80%",
  },
  underlineName: {
    position: "absolute",
    top: 0,
    height: 40,
    backgroundColor: "#fff",
    minWidth: "80%",
  },
  logo: {
    height: 400,
    width:120,
    marginBottom: 40,
  },
  footerHeading: {
    fontSize: 30,
    paddingLeft: 20,
  },
  footername: {
    paddingLeft: 20,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  parking: {
    position: "absolute",
    bottom: 20,
    right: 20,
    color: "#028A0f",
    fontSize: 60,
    backgroundColor: "#fff",
    padding:10
  },
});

const MyDocument = (qrUrl, logoUrl, userPhotoUrl, data) => (
  <Document style={styles.page}>
    <Page
      size="A4"
      style={{ ...styles.page, backgroundColor: cardColors[data.type] }}
      pageNumber={1}
    >
      <Img style={styles.userPhoto} src={userPhotoUrl} />
      <View style={styles.overlay}>
        <View style={styles.header}>
          <View style={styles.underline}></View>
          <Text style={styles.heading}>{data.type}</Text>
        </View>

        <View style={styles.nameContainer}>
          <Img style={styles.logo} src={logoWSL} />
          <View style={styles.footer}>
            <View style={styles.underlineName}></View>
            <Text style={styles.footername}>
              {data.first_name.toUpperCase()}
            </Text>
          </View>
          <View style={styles.footerBottom}>
            <View style={styles.underlineName}></View>
            <Text style={styles.footername}>
              {data.last_name.toUpperCase()}
            </Text>
          </View>
        </View>
      </View>
    </Page>
    <Page
      size="A4"
      style={{ ...styles.page, backgroundColor: cardColors[data.type] }}
      pageNumber={2}
    >
      <View style={styles.overlay}>
        <View style={styles.header}>
          <View style={styles.underline}></View>
          <Text style={styles.heading}>{data.type}</Text>
        </View>
        <Link
          style={{ fontSize: 35 }}
          src="https://docs.google.com/document/d/1wReFn2y4g11K82wJ6WJJdBbamrThpwAJUDejOFmpXEo"
        >
          CT SAFETY
        </Link>
        <View style={{ width: 450, height: 450 }}>
          <Img
            style={{
              position: "absolute",
              top: 195,
              left: 195,
              width: 60,
              height: 60,
              zIndex: 99,
            }}
            src={logoUrl}
          />
          <Img
            style={{
              position: "absolute",
              width: 450,
              height: 450,
              zIndex: -1,
            }}
            src={qrUrl}
          />
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerHeading}>ISSUED</Text>
          <View style={styles.underlineFooter}></View>
          <Text style={styles.footerDate}>{moment(data.issued).format("DD / MM / YYYY")}</Text>
        </View>
      </View>
      {parseInt(data.parking) ? <View style={styles.parking}>
        <Text >P</Text>
      </View> : false}
    </Page>
  </Document>
);

async function saveData(img1, img2, img3, data, tabWindow) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(
      await getPdfBlob(img1, img2, img3, data),
      "hardcard.pdf"
    );
  } else {
    var a = tabWindow.document.createElement("a");
    tabWindow.document.body.style.cursor = "auto";
    var url = (a.href = window.URL.createObjectURL(
      await getPdfBlob(img1, img2, img3, data)
    ));
    a.click();
    a.download = "hardcard.pdf";
  }
}

const getPdfBlob = async (img1, img2, img3, data, cb) => {
  const blob = await pdf(MyDocument(img1, img2, img3, data)).toBlob();
  //console.log(window.URL.createObjectURL(blob))
  if (cb) cb(blob);

  return blob;
};

let loadImage = function (url, handler, defaultImg) {
  var image = new Image();
  var url_image = url;
  image.onload = function () {
    handler(url_image);
  };
  image.onerror = function () {
    handler(defaultImg);
  };
  image.src = url_image;
};

const cardColors = {
  AAA: "#daa520",
  Silver: "#d3d3d3",
  "Box to Box": "#d3d3d3",
  "CT Surfer": "#ee5a34",
  Team: "#B90e0A",
  Coach: "#B90e0A",
  "Tour Crew": "#ffdc39",
  "Local Crew": "#ffdc39",
  Media: "#028A0f",
  ATHLETE: "#ee5a34",
  Crew: "#00FFFF",
};

function QrCode(props) {
  const { envUrl, userData } = React.useContext(Context);
  const [customLogo, setCustomLogo] = React.useState(false);
  const [userPhoto, setUserPhoto] = React.useState(false);
  const [pdfUrl, setpdfUrl] = React.useState(false);
  const [url, setUrl] = React.useState(false);
  const [issueDate, setIssueDate] = React.useState(props.data.issued);
  const [permission, setPermission] = React.useState(props.data);
  const [permissionList, setPermissionList] = React.useState([]);
  const [formUrl] = React.useState(props.url);

  React.useEffect(() => {
    //validateEmail(envUrl, decoded.fdid)
    loadImage(
      envUrl +
        "/logos/" +
        (userData.id || props.data.user_id) +
        ".png?" +
        Date.now(),
      setCustomLogo,
      logo
    );
    loadImage(
      envUrl + "/formUserPhoto/" + props.data.id + ".jpg?" + Date.now(),
      setUserPhoto,
      defualtUserImg
    );
    var canvas = document.getElementById("react-qrcode-logo");
    var ctx = canvas.getContext("2d");
    var img = canvas.toDataURL("image/jpg");
    setUrl(img);
  }, []);

  React.useEffect(() => {
    if (url && customLogo && userPhoto && permission)
      getPdfBlob(
        url,
        customLogo,
        userPhoto,
        {
          ...props.data,
          ...permission,
        },
        setpdfUrl
      );
  }, [url, customLogo, userPhoto, permission]);

  React.useEffect(() => {
    fetch(envUrl + "/php/hardCard/get/getHardCardsPerUser.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fuid: props.data.id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "success") setPermissionList(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className="EditVenue">
      <div className={classes.LargeQR}>
        <QRCode size={400} value={formUrl} logoImage={customLogo} />
      </div>
      <div className="css-joa6mv">
        <div className="css-1xhyf49">
          <h1 className="css-u6codq">
            {props.editable
              ? props.data.first_name + "'s digital card"
              : "Your digital card"}
          </h1>
        </div>
        <hr />
      </div>
      <div className="css-13p1bsc">
        {props.editable && (
          <div className="css-7xto2p">
            <div className={classes.pdfviewer}>
              {pdfUrl && props.editable && permission.issued ? (
                <PDFViewer showToolbar={true} style={{ height: 450 }}>
                  {MyDocument(url, customLogo, userPhoto, {
                    ...props.data,
                    ...permission,
                  })}
                </PDFViewer>
              ) : (
                false
              )}

              {props.editable && (
                <>
                  <Select
                    className={classes.permission}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={JSON.stringify(permission)}
                    onChange={(e) => {
                      let data = JSON.parse(e.target.value);
                      setPermission(() => data);
                      setIssueDate(data.issued);
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {permissionList.map((p) => (
                      <MenuItem
                        value={JSON.stringify({
                          type: p.flag_type,
                          color: cardColors[p.flag_type],
                          issued: moment(p.time_stamp).format("YYYY-MM-DD"),
                          parking: p.parking,
                        })}
                      >
                        {p.flag_type}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    disabled
                    fullWidth
                    className={classes.permission}
                    id="date"
                    label="Issue date"
                    type="date"
                    value={issueDate}
                    defaultValue={issueDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
        <div className={classes.Save}>
          <h2 className="css-lojbp">
            {" "}
            {props.editable ? "Users digital card" : "Your digital card"}
          </h2>
          <div className="css-uq1pv2">
            {props.editable
              ? "This is your users personal digital card."
              : "This is your personal digital card."}
          </div>
          <div className="css-vurnku">
            <button
              disabled={permission.issued ? false : true}
              className="Button DarkButton"
              onClick={() => {
                var windowReference = window.open();
                saveData(
                  url,
                  customLogo,
                  userPhoto,
                  {
                    ...props.data,
                     ...permission,
                  },
                  windowReference
                );
              }}
            >
              Download digital card
            </button>
            {props.editable && (
              <button
                disabled={permission.issued ? false : true}
                className="Button LightButton"
                onClick={() =>
                  reSendEmail(envUrl, props.id, userData.token, {
                    ...props.data,
                    ...permission,
                  })
                }
              >
                Email digital card
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCode;
