import React, { Suspense } from "react";
import "./FormsList.css";
import "../../sharedStyle/Shared.css";
import Menu from "../../sharedComponents/menuFormSite/Menu";
import { useParams } from "react-router-dom";
import Context from "../../context/Context";
import Loading from "../../sharedComponents/loading/Loading";
import Enquire from "../../sharedComponents/enquire/Enquire";
import { Switch, Route } from "react-router-dom";
const FormBuilder = React.lazy(() =>
  import("./components/formBuilder/FormBuilder")
);
const ViewForm = React.lazy(() => import("./components/viewForm/ViewForm"));
const AddPatron = React.lazy(() => import("./components/addPatron/AddPatron"));
const EditVenue = React.lazy(() => import("./components/editForm/EditForm"));
const Overview = React.lazy(() => import("./components/overview/Overview"));
const QRCode = React.lazy(() => import("../../sharedComponents/qrcode/QRCode"));
const Analytics = React.lazy(() => import("./components/analytics/Analytics"));

function YourVenue(props) {
  const { userData, setShowMenuButton, enquire, envUrl } =
    React.useContext(Context);
  const [show, setShow] = React.useState("Overview");
  const [data, setData] = React.useState(true);
  const { id } = useParams();
  setShowMenuButton(true);

  const getCategory = () => {
    console.log(id);
    fetch(envUrl + "/php/form/get/getCategory.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "success") alert(data.result);
        else {
          console.log(data.data);
          setData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    getCategory();
  }, []);

  return (
    <div className="Page">
      {enquire ? <Enquire /> : false}
      <div className="YourVenue">
        <Menu setShow={setShow} type={"forms"} />
        {!data ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/yourc/:id"
                render={(props) => (
                  <Overview
                    setShow={setShow}
                    data={data}
                    type={data.type_name}
                    id={id}
                  />
                )}
              />
              <Route
                exact
                path="/yourc/:id/code"
                render={(props) => (
                  <QRCode
                    data={data} 
                    type={data.type_name}
                    id={id}
                  />
                )}
              />
              <Route
                exact
                path="/yourc/:id/edit"
                render={(props) => (
                  <EditVenue
                    data={data}
                    type={data.type_name}
                    name={data.type_name}
                    id={id}
                    setData={setData}
                  />
                )}
              />
              <Route
                exact
                path="/yourc/:id/add"
                render={(props) => (
                  <AddPatron
                    setShow={setShow}
                    data={data}
                    type={data.type_name}
                    id={id}
                  />
                )}
              />
            </Switch>
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default YourVenue;

// import React from "react";
// import "../../sharedStyle/Shared.css";
// import Menu from "../../sharedComponents/menu/Menu";
// import { Link } from "react-router-dom";
// import Context from "../../context/Context";
// import "./FormList.css";

// const Place = (props) => {
//   console.log(props.data);
//   return (
//     <div className="Venue">
//       <Link
//         className="VenueInner"
//         to={"/yourf/form/" + props.data.id + "/" + props.data.name}
//       >
//         <h2 className="css-xhlesw">{props.data.name}</h2>
//         <button className="Button LightButton">View more</button>
//       </Link>
//     </div>
//   );
// };

// const Category = (props) => {
//   const { userData, envUrl } = React.useContext(Context);

//   const deleteCategory = (id) => {
//     fetch(envUrl + "/php/deleteCategory.php", {
//       method: "POST", // or 'PUT'
//       mode: "cors",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         token: userData.token,
//         id: id,
//       }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         if (data.result !== "success") alert(data.result);
//         else {
//           props.getVenueEvents();
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };

//   return (
//     <div className="CategoryForm">
//       <div className="VenuesInner">
//         <div className="VenueHeading">
//           <div className="VenueHeading HeadingContainer">
//             <h1 className="Heading">{props.data.name}</h1>
//             <div>
//               <Link
//                 className="Button DarkButton"
//                 to={"/register/" + props.data.name + "/" + props.id}
//               >
//                 Add new
//               </Link>
//               <button
//                 className="Button LightButton deleteCategory"
//                 onClick={() => deleteCategory(props.id)}
//               >
//                 Delete category
//               </button>
//             </div>
//           </div>
//           <hr />
//         </div>
//         <div className="VenuesContainer">
//           {props.data.data.map((x) => (
//             <Place type={props.data.name} data={x} />
//           ))}
//         </div>
//       </div>
//       <div className="VenueFooterContainer">
//         <Link
//           className="Button DarkButton"
//           to={"/yourc/category/" + props.id + "/" + props.data.name}
//         >
//           Category QR code
//         </Link>
//       </div>
//     </div>
//   );
// };

// function VenuesEvents() {
//   const { userData, setShowMenuButton, envUrl } = React.useContext(Context);
//   const [data, setData] = React.useState({});
//   setShowMenuButton(false);
//   const getVenueEvents = () => {
//     fetch(envUrl + "/php/getVenueEvents.php", {
//       method: "POST", // or 'PUT'
//       mode: "cors",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         token: userData.token,
//       }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         if (data.result !== "success") alert(data.result);
//         else {
//           setData(data.data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };

//   React.useEffect(() => {
//     getVenueEvents();
//   }, []);

//   return (
//     <div className="Page">
//       <div className="EditVenue">
//         <div className="EditHeading">
//           <div className="EditHeading">
//             <h1 className="Heading">Your forms</h1>
//             {/* <div>
//               <Link className="Button DarkButton" to={"/addcategory"}>
//                 Add category
//               </Link>
//             </div> */}
//           </div>
//           <hr />
//             {Object.keys(data).map((key) => (
//               <Category
//                 id={key}
//                 data={data[key]}
//                 getVenueEvents={getVenueEvents}
//               />
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default VenuesEvents;
