import React from "react";
import { decode, getQueryVariable } from "../../helpers/helpers";
import HardCard from "../../sharedComponents/hardCard/HardCard";

function YourHardCard(props) {
  const [decoded] = React.useState(
    JSON.parse(decode(getQueryVariable("data")))
  );

  return (
    <div className="EditVenue">
      {decoded && (
        <HardCard
        uid={decoded.uid}
          id={decoded.fuid}
          editable={false}
          data={decoded.data}
          url={btoa(
            JSON.stringify({
              fuid: decoded.fuid,
              uid: decoded.uid,
              "2.0": "1",
            })
          )}
        />
      )}
    </div>
  );
}

export default YourHardCard;
