import React from "react";
import "../../sharedStyle/Shared.css";
import Context from "../../context/Context";

function Settings(props) {
  const { userData, setLoggedIn, setUserData, envUrl } = React.useContext(Context);
  const [first, setFirst] = React.useState(userData.firstName);
  const [last, setLast] = React.useState(userData.lastName);
  const [email, setEmail] = React.useState(userData.email);
  const [emailConf, setEmailConf] = React.useState();
  const [password, setPassword] = React.useState();
  const [passwordConf, setPasswordConf] = React.useState();

  const ValidateEmail = (mail) => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const Delete = () => {
    if (!window.confirm("Are you really sure you want to delete?"))
      return false;
    fetch(envUrl+"/php/deleteAccount.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          setLoggedIn(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateEmail = () => {
    if (!ValidateEmail(email))
      return alert("You have entered an invalid email address!");
    if (email !== emailConf) return alert("emails dont match!");
    fetch(envUrl+"/php/updateEmail.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
         setUserData({...userData, email:email})
          setEmailConf("");
          alert("Email updated!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updatePassword = () => {
    if (password.length < 8)
      return alert("Password must be at least 8 characters");
    if (password !== passwordConf) return alert("passwords dont match!");
    fetch(envUrl+"/php/updatePassword.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          setPassword("");
          setPasswordConf("");
          alert("Password updated!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateInfo = () => {
    
    fetch(envUrl+"/php/updateInfo.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: first,
        lastName: last,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          setUserData({...userData, firstName:first, lastName:last})
          alert("Account info updated!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <main className="EditVenue">
      <div className="EditHeading">
        <div className="EditHeading">
          <h1 className="Heading">Your settings</h1>
        </div>
        <hr />
      </div>
      <div className="EditFormContainer">
        <div className="AboutVenue">
          <h3 className="css-1yb0tvj">Account info</h3>
          <div className="css-lkrid6">
            You can update your account infomation here.
          </div>
        </div>
        <div className="VenueFeildsContainer">
          <div className="VenueFeildsContainerInner">
            <div className="VenueFeilds">
              <div className="EditFeildContainer">
                <label className="Label">First name</label>
                <input
                  placeholder="Enter your first name"
                  className="Input"
                  value={first}
                  onChange={(x) => setFirst(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label className="Label">Last name</label>
                <input
                  placeholder="Enter your last name"
                  className="Input"
                  value={last}
                  onChange={(x) => setLast(x.target.value)}
                />
              </div>
            </div>
            <div className="UpdateContainer">
              <button onClick={updateInfo} className="Button DarkButton">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="EditFormContainer">
        <aside className="AboutVenue">
          <h3 className="css-1yb0tvj">Your email</h3>
          <div className="css-lkrid6">You can update your email here.</div>
        </aside>
        <div className="VenueFeildsContainer">
          <div className="VenueFeildsContainerInner">
            <div className="VenueFeilds">
              <div className="EditFeildContainer">
                <label for="instagram" className="Label">
                  New email
                </label>
                <input
                  placeholder="New email"
                  className="Input"
                  value={email}
                  onChange={(x) => setEmail(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label  className="Label">
                  Confirm email
                </label>
                <input
                  placeholder="Confirm email"
                  className="Input"
                  value={emailConf}
                  onChange={(x) => setEmailConf(x.target.value)}
                />
              </div>
            </div>
            <div className="UpdateContainer">
              <button onClick={updateEmail} className="Button DarkButton">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="EditFormContainer">
        <aside className="AboutVenue">
          <h3 className="css-1yb0tvj">Your password</h3>
          <div className="css-lkrid6">You can update your password here.</div>
        </aside>
        <div className="VenueFeildsContainer">
          <div className="VenueFeildsContainerInner">
            <div className="VenueFeilds">
              <div className="EditFeildContainer">
                <label  className="Label">
                  New password
                </label>
                <input
                type="password"
                  placeholder="8+ characters"
                  className="Input"
                  value={password}
                  onChange={(x) => setPassword(x.target.value)}
                />
              </div>
              <div className="EditFeildContainer">
                <label for="facebook" className="Label">
                  Confirm password
                </label>
                <input
                type="password"
                  placeholder="Confirm password"
                  className="Input"
                  value={passwordConf}
                  onChange={(x) => setPasswordConf(x.target.value)}
                />
              </div>
            </div>
            <div className="UpdateContainer">
              <button onClick={updatePassword} className="Button DarkButton">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="EditFormContainer">
        <aside className="AboutVenue">
          <h3 className="css-1yb0tvj">Delete account</h3>
          <div className="css-lkrid6">
            Once you delete your account, there is no going back. Make sure you
            are certain!
          </div>
        </aside>
        <div className="VenueFeildsContainer">
          <div className="VenueFeildsContainerInner">
            <div className="VenueFeilds">
              <div className="DeleteContainer">
                <label className="Label">Delete account</label>
                <div className="Button LightButton" onClick={Delete}>
                  Delete
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Settings;
