import React, { Suspense } from "react";
import "../../sharedStyle/Shared.css";
import "./FillForm.css";
import Complete from "../../sharedComponents/complete/Complete";
import Loading from "../../sharedComponents/loading/Loading";
import Context from "../../context/Context";
import Dependants from "./dependants/Dependants";

const Form = React.lazy(() => import("../../sharedComponents/form/Form"));

const checkIfAuto = (auto, id) => {
  console.log(auto);
  let savedData = localStorage.getItem("evntSafe");
  if (savedData) {
    savedData = JSON.parse(savedData);
    if (savedData[id] && parseInt(auto)) {
      return savedData[id].form;
    }
  }
  return false;
};

const addSaveData = (payload) => {
  let savedData = localStorage.getItem("evntSafe");
  if (savedData) {
    savedData = Object.assign({}, JSON.parse(savedData));
    savedData[payload.id] = payload;
    localStorage.setItem("evntSafe", JSON.stringify(savedData));
  } else {
    savedData = {};
    savedData[payload.id] = payload;
    localStorage.setItem("evntSafe", JSON.stringify(savedData));
  }
};

function FillForm(props) {
  const { envUrl } = React.useContext(Context);
  const [formData, setFormData] = React.useState({ task_data: [] });
  const [completeFrom, setCompleteFrom] = React.useState(false);
  const [completeDependants, setCompleteDependants] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [name, setName] = React.useState("");
  const [socials, setSocials] = React.useState();
  const [photo, setPhoto] = React.useState(false);
  const [fdid, setFdid] = React.useState();
  const [dependants, setDependants] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const savedData = localStorage.getItem("evntSafe");

  const onLoad = () => {
    setLoad(true);
    fetch(envUrl + "/php/getform.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (data.result === "success") {
          const autoSavedData = checkIfAuto(data.auto, id);
          if (autoSavedData) {
            onPost(autoSavedData);
          }
          setName(data.name);
          setFormData(data.data);
          setSocials(data.socials);
          setPhoto(parseInt(data.photo));
          setDependants(parseInt(data.dependants));
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error:", error);
      });
  };

  const onPost = (data) => {
    const form = data.form;
    const photo = data.photo;
    setLoad(true);
    fetch(envUrl + "/php/saveFormAnswers.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        form: form,
        id: id,
        photo: photo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "success") {
          addSaveData({ form: form, id: id });
          setLoad(false);
          setCompleteFrom(true);        
          setFdid(data.fdid);
          console.log("Success:", data);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    onLoad();
  }, []);

  return (
    <main className="Page">
      <div className="Form">
        <div className="EditHeading">
          <div className="EditHeading">
            <h1 className={completeFrom || load ? "FillHeading" : ""}>
              {name}
            </h1>
          </div>
          <hr />
        </div>
        {completeFrom && dependants && !completeDependants? (
          //<Complete socials={socials} />
          <Dependants fdid={fdid} fid={id} callBack={setCompleteDependants} />
        ) : load ? (
          <Loading />
        ) : completeFrom && socials ? (
          <Complete socials={socials} />
        ) : (
          <Suspense fallback={<Loading />}>
            <Form
              photo={photo}
              callBack={onPost}
              formData={formData.task_data}
            />
          </Suspense>
        )}
      </div>
    </main>
  );
}

export default FillForm;
