export function lower(obj) {
  for (var prop in obj) {
    if (typeof obj[prop] === "string") {
      obj[prop] = obj[prop].toLowerCase();
    }
    if (typeof obj[prop] === "object") {
      lower(obj[prop]);
    }
  }
  return obj;
}

export function jsUcfirst(string) {
  if(!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkIfInputs(selector) {
  const isInput = document.querySelectorAll(
    selector + " input:not([type=submit])"
  ).length;
  const isSelect = document.querySelectorAll(selector + " select").length;
  console.log(isSelect, isInput);
  if (!isInput && !isSelect)
    document.querySelector(selector + " input[type=submit]").style.display =
      "none";
}

export function getDomain() {
  return window.location.href.split("/").slice(0, 3).join("/");
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}


export function decode(data) {
  try {
    const decoded = atob(data);
    return decoded;
  } catch (e) {
    return false;
  }
}

export function encodeImageFileAsURL(file, callback) {
  var reader = new FileReader();
  reader.onloadend = function() {
    console.log(reader.result)
    callback(reader.result)
  }
  reader.readAsDataURL(file);
}

