import React from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import avatar from "../../assets/avatar.png";
import Context from "../../context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="AuthButtons">
      <Link to="/signin" className="Button LightButton">
        Sign In
      </Link>
      {/* <Link to="/signup" className="Button DarkButton">
        Sign Up
      </Link> */}
    </div>
  );
};

const Menu = () => {
  const { setShowMenu } = React.useContext(Context);
  return (
    <div className="css-10nf7hq">
      <div className="MenuOpts">
        <a className="css-1g3xstz Link" href="https://trac-qr.com/scanner">
          Scanner
        </a>
        <Link className="css-1g3xstz Link" to="/locations">
          Sites
        </Link>
        <Link className="css-1g3xstz Link" to="/home">
          Forms
        </Link>
        <Link className="css-1g3xstz Link" to="/users">
          Users
        </Link>
        <Link className="css-1g3xstz Link" to="/hardcards">
          Digital Cards
        </Link>
        <Link className="css-1g3xstz Link" to="/vaxreport">
          Vaccine report
        </Link>
        <Link className="css-1g3xstz Link" to="/vaxtierreport">
          Tier report
        </Link>

      </div>
      <button
        className="btn-unstyled ProfileButton"
        onClick={() => setShowMenu(true)}
      >
        <img
          alt="luke daniels"
          src={avatar}
          height="40"
          width="40"
          className="react-gravatar css-1pfsduu"
        />
      </button>
    </div>
  );
};

function Header(props) {
  const {
    loggedIn,
    showMenuButton,
    setMenuSmall,
    menuSmall,
  } = React.useContext(Context);
  return (
    <div className="Header">
      <div>
        {showMenuButton && loggedIn ? (
          <a
            style={{ padding: 5, paddingRight: 30, paddingLeft: 15 }}
            onClick={() => setMenuSmall(!menuSmall)}
          >
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faBars} />
          </a>
        ) : (
          false
        )}
        <Link to="/home">
          <img height="50px" width="auto" src={Logo} />
        </Link>
      </div>
      {!loggedIn ? <Login /> : <Menu />}
    </div>
  );
}

export default Header;
