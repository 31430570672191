import React from "react";
import classes from "./EditPhoto.module.css";
import Context from "../../../../context/Context";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";

const myTheme = {
  // Theme object to extends default dark theme.
};

function EditPhoto(props) {
  const { userData, envUrl } = React.useContext(Context);
  const [edit, setEdit] = React.useState(false);
  const editorRef = React.createRef();

  const finish = () => {
    const editorInstance = editorRef.current.getInstance();

    console.log(editorInstance.toDataURL());

    uploadPhoto(editorInstance.toDataURL(), props.fuid);
  };

  const uploadPhoto = (img, fuid) => {
    fetch(envUrl + "/php/formUser/uploadPhoto.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        img: img.split("data:image/png;base64,")[1],
        id: fuid,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          props.setPhoto(
            envUrl + "/formUserPhoto/" + fuid + ".jpg?" + Date.now()
          );
          alert("upload complete!");
          setEdit(!edit)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <label className={classes.PhotoIput} onClick={() => setEdit(!edit)}>
        <i class="fas fa-edit"></i>
      </label>
      {edit ? (
        <div className={classes.editor}>
          <div className={classes.editorInner}>
            <div className={classes.buttons}>
              <label className={classes.Close} onClick={() => finish(!edit)}>
              <i class="far fa-check-circle"></i>
              </label>
              <label className={classes.Close} onClick={() => setEdit(!edit)}>
              <i class="far fa-times-circle"></i>
              </label>
            </div>
            <ImageEditor
              ref={editorRef}
              includeUI={{
                loadImage: {
                  path: props.photo,
                  name: "SampleImage",
                },
                menu: ["crop", "flip", "rotate"],
                menuBarPosition: "bottom",
                uiSize: {
                  width: "700px",
                  height: "600px",
                },
              }}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
              }}
            />
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default EditPhoto;
