import React from "react";
import Context from "./Context";
import {getDomain} from '../helpers/helpers'



export default function Provider(props) {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [userData, setUserData] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [showMenuButton, setShowMenuButton] = React.useState(false);
  const [menuSmall, setMenuSmall] = React.useState(false);
  const [enquire, setEnquire] = React.useState(false);

  const[envUrl] = React.useState(process.env.NODE_ENV === `development` ? 'http://trac-qr-test.com' : getDomain())



  return (
    <Context.Provider
      value={{
        loggedIn, setLoggedIn,
        userData, setUserData,
        showMenu, setShowMenu,
        showMenuButton, setShowMenuButton,
        menuSmall, setMenuSmall,
        enquire, setEnquire,
        envUrl
      }}
    >
      {props.children}
    </Context.Provider>
  );
}