import React from "react";
import "./QRCode.css";
import "../../sharedStyle/Shared.css";
import { QRCode } from "react-qrcode-logo";
import logo from "../../assets/qr.png";
import headerPDF from "../../assets/whiteLogo.png";
import Context from "../../context/Context";
import { useParams } from "react-router-dom";
import { jsUcfirst, getDomain } from "../../helpers/helpers";

import {
  Font,
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image as Img,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#006e68",
    width: "100%",
    padding: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  section: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    height: 40,
    width: 100,
  },
  bold: {
    color: "#DEECEB",
    fontFamily: "Helvetica-Bold",
    fontSize: 50,
  },
  description: { fontFamily: "Helvetica-Bold", fontSize: 30 },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
    textAlign: "center",
  },
  footer: {
    color: "#d3d3d3",
    marginTop: 30,
    padding: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
    backgroundColor: "#fff",
    borderRadius: 20,
    textAlign: "center",
  },
  name: {
    fontSize: 30,
    marginTop: 10,
    textAlign: "left",
  },
});

const MyDocument = (qrUrl, logoUrl, name) => (
  <Document style={styles.page}>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Img style={{ width: null, height: "100%" }} style src={headerPDF} />
      </View>
      <View style={styles.text}>
        <View style={styles.section}>
          <Text style={styles.bold}>Do the right thing and check in</Text>
        </View>
      </View>
      <View style={styles.container}>
        <Text style={styles.description}>
          Open your phone camera and scan the QR code below to register your
          visit.
        </Text>
        <View>
          <Text style={styles.name}>{name}</Text>
        </View>
        <View style={{ marginTop: 30, width: 250, height: 250 }}>
          <Img
            style={{
              position: "absolute",
              width: 250,
              height: 250,
              zIndex: -1,
            }}
            src={qrUrl}
          />
          <Img
            style={{
              position: "absolute",
              top: 100,
              left: 100,
              width: 49.8,
              height: 49.8,
              zIndex: 99,
            }}
            src={logoUrl}
          />
        </View>
      </View>
      <Text style={styles.footer}> © TRACQR 2020</Text>
    </Page>
  </Document>
);

var saveData = function (img1, img2, name) {
  var windowReference = window.open();
  pdf(MyDocument(img1, img2, name))
    .toBlob()
    .then(
      (blob) => (windowReference.location = window.URL.createObjectURL(blob))
    );
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

let loadImage = function (userID, handler, envUrl) {
  var image = new Image();
  var url_image = envUrl + "/logos/" + userID + ".png?" + Date.now();
  image.onload = function () {
    handler(url_image);
  };
  image.onerror = function () {
    handler(logo);
  };
  image.src = url_image;
};

function QrCode(props) {
  const params = useParams();
  const { userData, setEnquire, envUrl } = React.useContext(Context);
  const [showUrl, setShowUrl] = React.useState(false);
  const [customLogo, setCustomLogo] = React.useState(false);
  const [url, setUrl] = React.useState(false);
  const [formUrl, setFormUrl] = React.useState(
    params.type === "form" ? "/fill?" : "/forms?"
  );

  const thisFileUpload = () => {
    document.getElementById("image-file").click();
  };

  const uploadLogo = async () => {
    let img = await toBase64(document.getElementById("image-file").files[0]);

    console.log(img);

    fetch(envUrl + "/php/uploadLogo.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        img: img,
        id: userData.id,
        type: params.type,
        token: userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result !== "success") alert(data.result);
        else {
          setCustomLogo(
            envUrl + "/logos/" + userData.id + ".png?" + Date.now()
          );
          alert("upload complete!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    loadImage(userData.id, setCustomLogo, envUrl);
    var canvas = document.getElementById("react-qrcode-logo");
    var ctx = canvas.getContext("2d");
    var img = canvas.toDataURL("image/jpg");
    setUrl(img);
  }, []);
  return (
    <div className="EditVenue">
      <div className="LargeQR">
        <QRCode
          size={400}
          value={getDomain() + formUrl + "id=" + params.id}
          logoImage={customLogo}
        />
      </div>
      <div className="css-joa6mv">
        <div className="css-1xhyf49">
          <h1 className="css-u6codq">{jsUcfirst(params.name) || props.type} QR code</h1>
        </div>
        <hr />
      </div>
      <div className="css-13p1bsc">
        <div className="css-7xto2p">
          <div className="css-cucqy9">
            {customLogo ? (
              <QRCode
                size={200}
                value={getDomain() + formUrl + "id=" + params.id}
                logoImage={customLogo}
              />
            ) : (
              false
            )}
          </div>
        </div>
        <div className="css-jefo7y">
          <h2 className="css-lojbp">Print your code</h2>
          <div className="css-uq1pv2">
            Download and print the QR code and place where suited for
            patrons/visitors/employees to check-in.
          </div>
          <div className="css-vurnku">
            <button
              className="Button DarkButton"
              onClick={() => saveData(url, customLogo, props.data.name)}
            >
              Download QR code
            </button>
            <input
              type="file"
              id="image-file"
              style={{ display: "none" }}
              onChange={uploadLogo}
              accept=".png"
            />
            <button
              className="Button LightButton"
              onClick={() => {
                if (userData.permissions == 3) thisFileUpload();
                else setEnquire(true);
              }}
            >
              Custom logo
            </button>
            <button
              className="Button LightButton Url"
              onClick={() => setShowUrl(!showUrl)}
            >
              View form url
            </button>
            {showUrl ? (
              <input
                className="Url"
                type="text"
                value={getDomain() + formUrl + "id=" + params.id}
              />
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCode;
