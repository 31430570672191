export function validateEmail(url, fuid) {
  fetch(url + "/php/validateEmail.php", {
    method: "POST", // or 'PUT'
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fdid: fuid,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.result !== "success") alert(data.result);
    })
    .catch((error) => {
      alert(error);
    });
}


export function reSendEmail(url, fuid, token, data) {
  fetch(url+"/php/hardCard/sendHardCard.php", {
    method: "POST", // or 'PUT'
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: fuid,
      token:token,
      data:data,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      
      alert(data.result);
    })
    .catch((error) => {
        alert(error);
    });
}