import React from "react";
import { Link } from "react-router-dom";
import Context from "../../context/Context";
import "../../sharedStyle/Shared.css";
import "./SignIn.css";

function SignIn(props) {
  const { setLoggedIn, setUserData, envUrl } = React.useContext(Context);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const atemptLogin = () => {
    fetch(envUrl + "/php/loginv2.php", {
      method: "POST", // or 'PUT'
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "success") alert(data.result);
        else {
          window.sessionStorage.setItem("user", JSON.stringify(data.user));
          setUserData(data.user);
          setLoggedIn(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="Page">
      <form
        className="SignUpInner"
        onSubmit={(e) => {
          e.preventDefault();
          atemptLogin();
        }}
      >
        <h1 className="Heading">Sign in to your account</h1>
        <div className="SignOption">
          Or{" "}
          <Link className="Link" to="/signup">
            create a new account
          </Link>
          .
        </div>
        <div className="SignInputContainer">
          <label className="Label">Email address</label>
          <input
            type="email"
            name="email"
            className="Input"
            value={email}
            onChange={(x) => setEmail(x.target.value)}
          />
        </div>
        <div className="SignInputContainer">
          <div className="ForgotPassword">
            <label className="Label">Password</label>
            <Link className="Link" to="/forgotpassword">
              Forgot password?
            </Link>
          </div>
          <input
            type="password"
            name="password"
            className="Input"
            value={password}
            onChange={(x) => setPassword(x.target.value)}
          />
        </div>
        <div className="SignButtons">
          <button type="submit" className="Button DarkButton">
            Sign in to TRACQR
          </button>

          <Link to="/signin" className="Button LightButton">
            Return home
          </Link>
        </div>
      </form>
    </div>
  );
}

export default SignIn;
