import React from "react";
import "../../sharedStyle/Shared.css";
import "./Faq.css";
import Context from "../../context/Context";
import { Link } from "react-router-dom";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

function Faq() {
  const { setShowMenuButton } = React.useContext(Context);
  setShowMenuButton(false)
  return (
    <div className="Page">
      <div className="Faq">
        <br />
        <br />
        <div className="HeadingContainer">
          <h1 className="Heading">Frequently Asked Questions</h1>
        </div>
        <br />
        <br />
        <h3>Companies</h3>
        <hr />
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>How do I sign up?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Create an <Link to="/signup">account here</Link>. One your done
                create your custom form or use the defualt print your QR code.
                Your now finished! Sit back and relax.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can I change or edit the name of my registered forms?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Of course. Simply log-in through our website head your
                respective category click your place and then select edit place
                button.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                If I have a large business with a number of locations, do I need
                to register each one?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes. Companies may register multiple forms for multiple
                categories e.g job sites: job site 1, job site 2 etc.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                If I run/own multiple companies do I need an account for each
                one?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                No. You can simply create a new category within your account to
                accomdate each companies registered forms e.g job sites: job
                site 1, job site 2 , cafes: cafe 1, cafe 2 etc..
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What if employess/patrons/visitors cant check-in through the
                app?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                If employess/patrons/visitors cant check in (e.g. no camera, or
                internet) you can check them in manaully by logging in to the
                website and using the add check-in functionality.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How can I protect the privacy of my employess/patrons/visitors
                data?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Make sure you keep your TRACQR account details secure. If you
                choose to download your employess/patrons/visitors records, this
                should also be kept confidential.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Is the data encrypted?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                TRACQR data is stored on Amazon Web Services and transferred via
                HTTPS
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Why does my company/business need this?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                As of June 1 2020, comapnies will be allowed to welcome guests
                back and from 12:01am Friday, 24 July
                2020 a digital record must be created within 24
                hours.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <br /> <br />
        <h3>Patrons/employees/visitors</h3>
        <hr />
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Does this app use location services or track my customers?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                No. This app only records patrons/employees/visitors time of entry and form data.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I check in to a place?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Simply open your device's camera, hover over the QR code follow the prompts to your devices web browser, fill in the form and your done!
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Do I need to fill out the form each checkin?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Depends. This is up to company and how they set up their check
                proccess.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can I remove my data from TRACQR?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Data must be kept for 28 days for the place operator to meet
                legal compliance requirements. 28 days from check-in, you can request
                TRACQR to delete your data.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <br /> <br />
      </div>
    </div>
  );
}

export default Faq;
