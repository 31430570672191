import React from "react";
import "../../sharedStyle/Shared.css";
import './Loading.css'
import Loader from '../../assets/Loader.svg'

function Loading() {
  return (
    <div className="Loading">
        <img src={Loader} />
    </div>
  );
}

export default Loading;
